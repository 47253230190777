import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import axiosClient from "../../config/axios-client";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import { Editor } from "@tinymce/tinymce-react";
import editorConfig from "../../config/editorConfig";
import MineralFormLoading from "../../components/ui/loadings/MineralFormLoading";
import { Link } from "react-router-dom";

export default function EditMineralHistoryPage() {

    const { mineralId } = useParams();

    //states
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [mineral, setMineral] = useState(null);
    const [mineralImage, setMineralImage] = useState(null);
    const [isSending, setIsSending] = useState();

    //refs
    const bodyArRef = useRef('');
    const bodyEnRef = useRef('');

    //get mineral 
    const getMineral = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/minerals/${mineralId}`)
            .then(({ data }) => {
                setMineral(data.mineral);

                setMineralImage(`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`);
                if (!data.mineral) {
                    navigate('/');
                }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })


    }
    const handleOnSubmitMineralUpdate = () => {
        setErrors({});
        const payload = {
            history_description_ar: bodyArRef.current.getContent(),
            history_description_en: bodyEnRef.current.getContent(),
        }

        axiosClient
            .post(`/dashboard/minerals/${mineralId}/history`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                navigate('/minerals', {
                    replace: 'true'
                });

                showToastSuccess('تم تعديل بنجاح');
            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }


    useEffect(() => {
        getMineral();
    }, [])

    if (isLoading) {
        return (<>
            <MineralFormLoading />
        </>)
    }
    return (<>
        <div className='flex gap-0.5 font-bold mb-4'>
            <Link className='hover:text-secondary' to={'/minerals'} >
                المعادن
            </Link>
            <div>/</div>
            <div className='cursor-default' to={`/minerals/${mineral && mineral.id}`} >
                {mineral && mineral.name_ar}
            </div>
            <div>/</div>
            <h2 className='text-info'>
                تعديل التاريخ
            </h2>
        </div>
        <div className="bg-white p-4 border-2">
            <div className="flex flex-col gap-2 mb-4">
                <label className="font-bold">بالعربية *</label>
                <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (bodyArRef.current = editor)}
                        initialValue={mineral?.history_description_ar}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-4">
                <label className="font-bold">بالإنجليزية</label>
                <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (bodyEnRef.current = editor)}
                        initialValue={mineral?.history_description_en}
                    />
                </div>
            </div>

            <button
                onClick={handleOnSubmitMineralUpdate}
                className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold duration-150 ease-in-out transform"
            >
                تعديل
            </button>

        </div>
    </>)
}