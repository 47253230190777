import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/authContext/AuthContext';
import { scrollToTop } from '../helper';
import { AUTH_ACTIONS } from '../contexts/authContext/authActions';
import axiosClient from '../config/axios-client';

export default function Splash() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { authState, authDispatch } = useAuth();

  const getUser = ()=>{
    
    if (!authState.token) {
      authDispatch({ type: AUTH_ACTIONS.LOGOUT });
      setIsLoading(false)
      navigate('/session/login', { replace: true });
      return;
    }
    setIsLoading(true);
    axiosClient
      .get('/session/me')
      .then(({ data }) => {
        if (data.user == null) {
          authDispatch({ type: AUTH_ACTIONS.LOGOUT });
          navigate('/session/login', { replace: true });
          return;
        }

        authDispatch({
          type: AUTH_ACTIONS.SET_CURRENT_USER,
          payload: {
            user: data.user,
          },
        });
      })
      .catch((error) => {

        authDispatch({ type: AUTH_ACTIONS.LOGOUT });
        navigate('/session/login', { replace: true });
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    getUser()    
  }, [authState.token]); // Add location.pathname to dependencies

  if (isLoading) {
    return (
      <div className="z-0 flex h-screen w-full items-center justify-center">
        <div className="flex flex-col justify-center gap-8 text-center">
          <img
            className="h-[256px] w-[256px] animate-pulse"
            src="/assets/logos/app_logo.svg"
            alt="Logo"
          />
        </div>
      </div>
    );
  } else {
    return <Outlet />;
  }
}