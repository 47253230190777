export default function MineralsRegionTableLoading (){
    return (
        <>
        <tr>
            <td colSpan={5} className="bg-white">
                <div className="animate-pulse p-16 font-bold">
                جاري تحميل الفرص الاستثمارية...
                </div>
            </td>
        </tr>
        </>
    )
}