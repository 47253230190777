import axiosClient from "../../config/axios-client";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import editorConfig from "../../config/editorConfig";
import CountrySourceFormLoading from "../../components/ui/loadings/CountrySourceFormLoading";
import { Link } from "react-router-dom";

export default function EditOpportunitiesSourcePage() {
    const { countryId } = useParams();
    const navigate = useNavigate();



    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState();
    const [isSending, setIsSending] = useState();
    const [country, setCountry] = useState(null);
    const [source, setSource] = useState(null);

    const sourceArRef = useRef(null);
    const sourceEnRef = useRef(null);
    const sourceFrRef = useRef(null);
    const sourceLinkRef = useRef(null);

    const getSource = () => {
        setIsLoading(true)
        axiosClient
            .get(`/dashboard/countries/${countryId}/source`)
            .then(({ data }) => {
                setSource(data.source);
            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })
    }
    const getCountry = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/countries/${countryId}`)
            .then(({ data }) => {
                setCountry(data.country);

                // if (!data.country) {
                //     navigate('/');
                // }

            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const handleOnSubmit = () => {
        setErrors({});
        const payload = {
            source_ar: getRefValue(sourceArRef),
            source_en: getRefValue(sourceEnRef),
            source_fr: getRefValue(sourceFrRef),
            link: getRefValue(sourceLinkRef),
        }

        axiosClient
            .post(`/dashboard/countries/${countryId}/source`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                if (data.source) {
                    navigate('/opportunities', {
                        replace: 'true'
                      });
                    showToastSuccess('تم تحديث مصدر فرص الدولة بنجاح.')
                }
            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }
    useEffect(() => {
        getSource()
        getCountry()
    }, [countryId])

    if (isLoading) {
        return <>
            <CountrySourceFormLoading />
        </>
    }
    return (<>
        <div className="">

            <div className='flex gap-0.5 text-xs font-bold mb-5'>
                <div>/</div>
                <Link className='' to={'/opportunities'} >
                    الفرص الاستثمارية
                </Link>
                <div>/</div>
                <h2 className='text-warning'>
                    تعديل المصدر
                </h2>
            </div>
            <h1 className="text-3xl font-bold my-4">
                تعديل المصدر
            </h1>


            <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
                <div className="my-4 border-2 bg-white p-4 py-2">


                    {country ?
                        <div className="flex items-center gap-2 text-primary ">
                            <img
                                className="h-[62px] min-w-[82px] object-cover"
                                src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                                alt={`${country.name_ar}`}
                            />
                            <div className="font-bold text-xl">{country.name_ar}</div>
                        </div>
                        :
                        <div className="flex items-center gap-2 text-primary  animate-pulse ">
                            <div
                                className="h-[62px] min-w-[62px] bg-muted rounded-full"

                            />
                            <div className="font-bold text-xl p-2 w-[82px] bg-muted"> </div>
                        </div>
                    }
                </div>
            </div>

            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالعربية</label>
                    <input
                        defaultValue={source?.source_ar}
                        ref={sourceArRef}
                        className="outline-none border bg-white focus:bg-white py-2 px-6"
                        type="text"
                    />

                </div>
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        بالإنجليزية
                    </label>
                    <input
                        defaultValue={source?.source_en}
                        ref={sourceEnRef}
                        className="outline-none border bg-white focus:bg-white py-2 px-6"
                        type="text"
                    />

                </div>
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        بالفرنسية
                    </label>
                    <input
                        defaultValue={source?.source_fr}
                        ref={sourceFrRef}
                        className="outline-none border bg-white focus:bg-white py-2 px-6"
                        type="text"
                    />

                </div>
                <div dir="rtl" className="flex grow min-w-full flex-col  space-y-2">
                    <label className="font-bold">رابط معلومات إضافية</label>
                    <input
                        defaultValue={source?.link}
                        dir="ltr"
                        placeholder="https://..."
                        ref={sourceLinkRef}
                        className="outline-none border bg-white focus:bg-white py-2 px-6"
                        type="url"
                    />
                </div>
            </div>

            <div className="my-5">
                <button
                    onClick={handleOnSubmit}
                    className="py-3 px-6 bg-primary hover:bg-primary/75 w-full text-white font-bold duration-150 ease-in-out transform"
                >
                    تعديل
                </button>
            </div>
        </div>
    </>)
}
