import { showToastError, showToastSuccess } from "../helper";

export default function Home() {

  
  return (
    <div className="col-span-12">
      <div
        className="flex items-center justify-center"
        style={{ height: "100vh" }}
      >
        <img
          className="h-[258px]"
          src="/assets/logos/app_logo.svg"
          alt="platform logo"
        />
      </div>
    </div>
  );
}
