import { useEffect, useState } from "react";
import axiosClient from "../../config/axios-client";
import MineralsTableLoading from "../../components/ui/loadings/MineralsTableLoading";
import { useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { BsFiletypeXlsx } from "react-icons/bs";
import { FaFile, FaFileCsv, FaFileExcel } from "react-icons/fa6";
import { BsFileEarmarkFill } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { toast } from "react-toastify";
import moment from "moment/moment";

export default function CountryUserPage() {

    const { userId } = useParams();
    const [countrysUser, setCountrysUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getcountrysUser = async () => {
        setIsLoading(true);

        axiosClient
            .get(`/dashboard/form/countries_users/${userId}`)
            .then(({ data }) => {
                setCountrysUser(data.country_user);
            })
            .catch((err) => {

            }).finally(() => {
                setIsLoading(false);
            });
    };
    const downLoadFile = (fileId, fileName) => {
        axiosClient
            .get(`/dashboard/form/files/${fileId}/download`, {
                responseType: 'blob', // Important for file downloads
            })
            .then((response) => {

                const contentType = response.headers['content-type'];
                const disposition = response.headers['content-disposition'];
                let filename = `${fileName}_file (${fileId})`;
                if (disposition) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|([^;\n]*))/;
                    const results = matches.exec(disposition);
                    if (results && results[1]) {
                        filename = results[1].replace(/['"]/g, '');
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // Use the extracted filename
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((err) => {
                console.log(err)
                toast.error('حدث خطا اثناء تحميل الملف')
            }).finally(() => {
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getcountrysUser();
    }, []);
    return (<>
        <div className="bg-white p-4 border-2 border-muted">

            <div className="flex flex-col gap-4">
                <div>
                    <div className="font-bold">الدولة</div>
                    <div className="">{countrysUser?.country?.name_ar || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold">الجهة المعنية</div>
                    <div className="">{countrysUser?.title || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold">الاسم الكامل </div>
                    <div className="">{countrysUser?.full_name || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold">المهنة</div>
                    <div className="">{countrysUser?.profession || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold">البريد الإلكتروني
                    </div>
                    <div className="">{countrysUser?.email || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold">الجوال</div>
                    <div className="">{countrysUser?.tel || 'غير متوفر '}</div>
                </div>
                <div>
                    <div className=" font-bold align-top">الملفات</div>
                    <div className="">
                        {/* files */}
                        <div className="flex gap-4 flex-wrap mt-2">
                            {countrysUser?.files && countrysUser?.files?.map((file) => <div className="bg-light hover:bg-muted p-2 px-4 border-2 group cursor-pointer " onClick={() => downLoadFile(file.id, countrysUser?.country?.name_ar)} title="تحميل">
                                        <div className="flex gap-2 items-center">
                                            <IoMdDownload size={20} />
                                            <div className="flex gap-2">
                                                <div>
                                                {file.type}
                                                </div>
                                                <div>
                                                {moment(file.created_at).format('LL')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>)
}
