import { AUTH_ACTIONS } from './authActions';

export const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      localStorage.setItem('ACCESS_TOKEN', action.payload.token);
      return {
        ...state,
        currentUser: action.payload.user,
        token: action.payload.token,
      };
    case AUTH_ACTIONS.LOGOUT:
      localStorage.removeItem('ACCESS_TOKEN');
      return {
        ...state,
        currentUser: null,
        token: null,
      };
    case AUTH_ACTIONS.SET_LICENSE_DEMAND:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          company: {
            ...state.currentUser.company,
            license_demands: [action.payload.licenseDemand]
          }
        }
      };
    case AUTH_ACTIONS.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.user,
      };
    default:
      return state;
  }
};
