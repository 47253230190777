import moment from "moment";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import DatePicker from "../../components/ui/DatePicker";
import Select from "react-select";
import { Link } from "react-router-dom";
import { getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import axiosClient from "../../config/axios-client";
import OpportunityFormLoading from "../../components/ui/loadings/OpportunityFormLoading";


export default function EditOpportunitiesPage() {
    // variables 
    const { countryId } = useParams();
    const { opportunityId } = useParams();

    const navigate = useNavigate();

    // states
    const [errors, setErrors] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState();
    const [selectedMinerals, setSelectedMinerals] = useState(null);
    const [minerals, setMinerals] = useState([]);
    const [mineralsIds, setMineralsIds] = useState("");
    const [country, setCountry] = useState(null);
    const [isMineralsLoading, setMineralsLoading] = useState(true);
    const [opportunity, setOpportunity] = useState();

    const [startedAtDate, setStartedAtDate] = useState();
    const [hasTime, setHasTime] = useState(false);
    const [hasArea, setHasArea] = useState(false);

    // refs
    const titleArRef = useRef(null);
    const titleEnRef = useRef(null);
    const titleFrRef = useRef(null);
    const mineralsArRef = useRef(null);
    const mineralsEnRef = useRef(null);
    const mineralsFrRef = useRef(null);
    const typeArRef = useRef(null);
    const typeEnRef = useRef(null);
    const typeFrRef = useRef(null);
    const areaRef = useRef(null);
    const attachmentLinkRef = useRef(null);
    const categoryRef = useRef(null);


    //function 
    const getOpportunity = () => {
        setErrors({});
        axiosClient
            .get(`/dashboard/countries/${countryId}/opportunities/${opportunityId}`)
            .then(({ data }) => {
                setOpportunity(data.opportunity);

                // if (!data.country) {
                //     navigate('/');
                // }
                setSelectedMinerals(data.opportunity?.minerals)
                setHasTime(data.opportunity?.started_at)
                setHasArea(data.opportunity?.area)

            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })
    }
    const getCountry = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/countries/${countryId}`)
            .then(({ data }) => {
                setCountry(data.country);

                // if (!data.country) {
                //     navigate('/');
                // }

            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })
    };
    const getMinerals = () => {
        setMineralsLoading(true)
        axiosClient
            .get(`/dashboard/minerals`)
            .then(({ data }) => {
                setMinerals(data.minerals);


                // if (!data.country) {
                //     navigate('/');
                // }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })

    };
    const handleTimeCheckboxChange = (event) => {
        setHasTime(event.target.checked);
    };
    const handleAreaCheckboxChange = (event) => {
        setHasArea(event.target.checked);
        if (!event.target.checked) {
            areaRef.current = null;
        }
    };
    const handleOnChangeMinerals = (minerals) => {
        let text_ar = "";
        let text_fr = "";
        let text_en = "";
        let ids = [];
        if (minerals) {
            minerals.forEach((mineral, index) => {
                if (index == 0) {
                    text_ar += mineral.label;
                    if (mineral.name_en) {
                        text_en += mineral.name_en;
                    }
                    if (mineral.name_fr) {
                        text_fr += mineral.name_fr;
                    }
                } else {
                    text_ar += ` و${mineral.label}`;
                    if (mineral.name_en) {
                        text_en += `, ${mineral.name_en}`;
                    }
                    if (mineral.name_fr) {
                        text_fr += `, ${mineral.name_fr}`;
                    }
                }
            });
            minerals.forEach((mineral, index) => {
                ids.push(mineral.value);
            });

        }
        setSelectedMinerals(minerals);
        setMineralsIds(ids);
        mineralsArRef.current.value = text_ar;
        mineralsEnRef.current.value = text_en;
        mineralsFrRef.current.value = text_fr;
    };
    const handleOnChangeStartedAtDate = (date) => {
        setStartedAtDate(date);
    }

    const handleOnSubmit = () => {
        setErrors({});
        const payload = {
            category_id: getRefValue(categoryRef),
            title_ar: getRefValue(titleArRef),
            title_en: getRefValue(titleEnRef),
            title_fr: getRefValue(titleFrRef),
            type_ar: getRefValue(typeArRef),
            type_en: getRefValue(typeEnRef),
            type_fr: getRefValue(typeFrRef),
            raw_materials_ar: getRefValue(mineralsArRef),
            raw_materials_en: getRefValue(mineralsEnRef),
            raw_materials_fr: getRefValue(mineralsFrRef),
            area: getRefValue(areaRef),
            attachment_link: getRefValue(attachmentLinkRef),
            minerals_ids: mineralsIds,
            started_at: startedAtDate,
        }

        axiosClient
            .post(`/dashboard/countries/${countryId}/opportunities/${opportunityId}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                if(data.opportunity){
                    navigate('/opportunities', {
                            replace: 'true'
                        });
                    showToastSuccess('تم تعديل الفرصة بنجاح');
                }

            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }


    useEffect(() => {
        getMinerals()
        getCountry()
        getOpportunity();
    }, [countryId])

    if (isLoading) {
        return (<>
            <OpportunityFormLoading />
        </>)
    }
    return (<>
        <div className="">
            <div className='flex gap-0.5 text-xs font-bold mb-5'>
                <div>/</div>
                <Link className='' to={'/opportunities'} >
                    الفرص الاستثمارية
                </Link>
                <div>/</div>
                {opportunity && opportunity.title_ar}
                <div>/</div>
                <h2 className='text-warning'>
                    تعديل الفرصة
                </h2>
            </div>
            <div className="col-span-12 ">
                <div className="flex mb-2">

                    <h1 className="text-3xl font-bold">
                        تعديل الفرصة
                    </h1>
                </div>
            </div>

            <div dir="rtl" className="flex flex-col space-y-2 col-span-12">
                <label className="font-bold">الدولة</label>
                {country ? (
                    <div className="flex flex-col space-y-2">
                        <div>
                            <img
                                className="h-16"
                                src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                                alt={`${country.name_ar}`}
                            />
                        </div>
                        <div>{country.name_ar}</div>
                    </div>
                ) : (
                    <div className="h-16 w-[104px] bg-gray-200" />
                )}
            </div>

            <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
                عنوان
            </div>
            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالعربية</label>
                    <input
                        ref={titleArRef}
                        defaultValue={opportunity?.title_ar}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_title_ar"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالإنجليزية</label>
                    <input
                        dir="ltr"
                        ref={titleEnRef}
                        defaultValue={opportunity?.title_en}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_title_en"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالفرنسية</label>
                    <input
                        dir="ltr"
                        ref={titleFrRef}
                        defaultValue={opportunity?.title_fr}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_title_fr"
                    />
                </div>

            </div>

            <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
                خامات
            </div>
            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex min-w-full flex-col space-y-2 col-span-12">
                    <label>اختر </label>

                    <Select
                        value={selectedMinerals?.map((item) => {
                            return {
                                label: item.name_ar,
                                image: item.image,
                                value: item.id // Assuming you want to keep the ID for some purpose
                            };
                        })}
                        isMulti
                        onChange={handleOnChangeMinerals}
                        options={minerals?.map((item) => {
                            return {
                                label: item.name_ar,
                                image: item.image,
                                value: item.id // Assuming you want to keep the ID for some purpose
                            };
                        })}
                        // loading={isCountriesLoading}
                        // isDisabled={isCountriesLoading}
                        isSearchable
                        placeholder="الدولة"
                        isClearable
                        classNames={{
                            searchContainer: "px-4 pb-2 relative w-full",
                            searchBox: "w-full outline-none py-2 px-4 rounded-md bg-muted/20",
                            searchIcon: "absolute left-6 w-6 h-6 top-1.5",
                        }}
                        formatOptionLabel={(data) => (
                            <li
                                dir="rtl"
                                className={`block transition duration-200 px-4 cursor-pointer select-none truncate rounded-md mt-2 ${data.isSelected
                                    ? `bg-blue-200 text-black font-bold`
                                    : `text-black font-semibold`
                                    }`}
                            >
                                <div className="flex items-center space-x-4 space-x-reverse">
                                    <img
                                        className="h-[32px] "
                                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${data.image}`}
                                        alt=""
                                    />
                                    <div>{data.label}</div>
                                </div>
                            </li>
                        )}
                    />
                </div>
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالعربية</label>
                    <input
                        defaultValue={opportunity?.raw_materials_ar}
                        ref={mineralsArRef}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_mienrals_ar"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالإنجليزية</label>
                    <input
                        dir="ltr"
                        defaultValue={opportunity?.raw_materials_en}
                        ref={mineralsEnRef}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_mienrals_en"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالفرنسية</label>
                    <input
                        dir="ltr"
                        defaultValue={opportunity?.raw_materials_fr}
                        ref={mineralsFrRef}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_mienrals_fr"
                    />
                </div>
            </div>

            <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
                نوع
            </div>
            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالعربية</label>
                    <input
                        ref={typeArRef}
                        defaultValue={opportunity?.type_ar}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_type_ar"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالإنجليزية</label>
                    <input
                        dir="ltr"
                        ref={typeEnRef}
                        defaultValue={opportunity?.type_en}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_type_en"
                    />
                </div>
                <div className="flex grow flex-col  space-y-2">
                    <label className="font-bold">بالفرنسية</label>
                    <input
                        dir="ltr"
                        ref={typeFrRef}
                        defaultValue={opportunity?.type_fr}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="text"
                        name="opportunity_type_fr"
                    />
                </div>
                <div className="flex flex-1 min-w-full grow flex-col space-y-2">
                    <label className="font-bold"> الصنف </label>
                    <select
                        ref={categoryRef}
                        className="outline-none border border-muted py-1.5 px-6"
                        name="mineral_type"
                    >
                        {/*         
                  <option value="CLEAN_ENERGY">معادن الطاقة النظيفة</option>
                  <option value="INDUSTRIAL_MINERALS">معادن الصناعية</option>
                  <option value="MINERALS_PRICES">أسعار المعادن</option> */}
                        <option value="1">معادن الطاقة النظيفة</option>
                        <option value="2">معادن الصناعية</option>
                        <option value="3">أسعار المعادن</option>
                    </select>
                </div>
            </div>
            <div className="bg-primary col-span-12 py-2 px-4 text-white font-bold select-none">
                ...
            </div>
            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        <div className="flex items-end space-x-2 space-x-reverse">
                            <div>
                                المساحة (كم<sup>2</sup> )
                            </div>
                            <div>
                                <input type="checkbox" onChange={handleAreaCheckboxChange} />
                            </div>
                        </div>
                    </label>
                    {hasArea ? (
                        <input
                            ref={areaRef}
                            className="outline-none border bg-light focus:bg-white py-2 px-6"
                            min={0}
                            pattern="\d+"
                            type="number"
                            name="opportunity_area"
                        />
                    ) : (
                        <input
                            disabled
                            value={"غير محددة"}
                            type="text"
                            className="outline-none border bg-light focus:bg-white py-2 px-6"
                        />
                    )}
                </div>
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        <div className="flex items-end space-x-2 space-x-reverse">
                            <div>تاريخ الفرصة</div>
                            <div>
                                <input type="checkbox" onChange={handleTimeCheckboxChange} checked={hasTime} />
                            </div>
                        </div>
                    </label>
                    {hasTime ? (

                        <DatePicker defaultSelectedDate={opportunity?.started_at} getSelectedDate={handleOnChangeStartedAtDate} />
                    ) : (
                        <input
                            disabled
                            value={"غير محدد"}
                            type="text"
                            className="outline-none border bg-light focus:bg-white py-2 px-6"
                        />
                    )}
                </div>
                <div dir="rtl" className="flex grow min-w-full flex-col  space-y-2">
                    <label className="font-bold">رابط معلومات إضافية</label>
                    <input
                        dir="ltr"
                        placeholder="https://..."
                        ref={attachmentLinkRef}
                        defaultValue={opportunity?.attachment_link}
                        className="outline-none border bg-light focus:bg-white py-2 px-6"
                        type="url"
                        name="opportunity_attachment_link"
                    />
                </div>
            </div>



            <div className="col-span-12 flex">
                <button
                    onClick={handleOnSubmit}
                    className="py-3 px-6 bg-blue-600 hover:bg-blue-500 w-full text-white font-bold"
                >
                    تعديل
                </button>
            </div>
        </div>
    </>)
}
