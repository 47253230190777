import { useEffect, useState } from "react";
import axiosClient from "../../config/axios-client";
import { Link } from "react-router-dom";
import { getCategoryById } from "../../helper";
import { FaPen, FaRegFolder, FaRegFolderOpen } from "react-icons/fa6";

import { FaTrash, FaLink } from "react-icons/fa6";
import { LuFileCheck, LuFileX } from "react-icons/lu";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import CountriesOpportunitiesTableLoading from "../../components/ui/loadings/CountriesOpportunitiesTableLoading";
import MineralsRegionTableLoading from "../../components/ui/loadings/OpportunitiesTableLoading";
import { FiEdit2 } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";

export default function OpportunitiesPage() {

    const [countriesOpportunities, setCountriesOpportunities] = useState(null);
    const [isLoading, setIsLoading] = useState(true);



    const getOpportunities = async () => {
        setIsLoading(true);

        axiosClient
            .get(`/dashboard/countries/opportunities`)
            .then(({ data }) => {
                setCountriesOpportunities(data.countries);
            })
            .catch((err) => {

            }).finally(() => {
                setIsLoading(false);
            })
            ;
    };



    useEffect(() => {
        getOpportunities();
    }, []);
    return (<>
        <div className="">
            <div className='flex gap-0.5 font-bold mb-4'>
                <h2 className='text-primary font-bold text-lg '>
                    الفرص الاستثمارية
                </h2>
            </div>
            <table className="w-full text-right">
                <thead className="text-white text-center bg-primary">
                    <th className="p-3 px-4 "></th>
                    <th className="p-3 px-4 ">الدولة</th>
                    <th className="p-3 px-4 ">المعادن</th>
                    <th className="p-3 px-4 line-clamp-1">عدد الفرص</th>
                    <th className="p-3 px-4 ">Actions </th>
                </thead>
                {!isLoading
                    ?
                    <tbody>
                        {countriesOpportunities?.map((countryOpportunity) => <OpportunitiesTableItem countryOpportunity={countryOpportunity} />)}
                    </tbody>
                    :
                    <CountriesOpportunitiesTableLoading />
                }
            </table>
        </div>

    </>)
}

const OpportunitiesTableItem = ({ countryOpportunity }) => {

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [opportunities, setOpportunities] = useState(null);
    const [opportunitiesIsLoading, setOpportunitiesIsLoading] = useState();

    const handleOpen = () => {
        console.log('')
        setIsOpenMenu(!isOpenMenu);
        handleFetchOpportunitiesByCountry(countryOpportunity.id)
    }

    const handleFetchOpportunitiesByCountry = (country) => {
        setOpportunitiesIsLoading(true);
        axiosClient
            .get(`/dashboard/countries/${country}/opportunities`)
            .then(({ data }) => {
                console.log(data)
                setOpportunities(data.country.opportunities)
            })
            .catch((err) => {

            }).finally(() => {
                setOpportunitiesIsLoading(false);
            })
            ;
    }

    return (<>
        <tr className="text-center odd:bg-white border-2 border-top-none bg-light">
            <td className={`py-2 px-4 `}>
                {countryOpportunity?.count > 0
                    ?
                    <button onClick={handleOpen} className="cursor-pointer">
                        {!isOpenMenu ?
                            <FaRegFolder size={22} className="text-primary  m-auto" />
                            :
                            <FaRegFolderOpen size={22} className="text-primary  m-auto" />
                        }
                    </button>
                    :
                    <button className="" title="لا يوجد فرص استثمارية" disabled>
                        <FaRegFolder size={22} className="opacity-50 m-auto" />
                    </button>
                }
            </td>
            <td className="text-start py-2 px-4">
                <div className="flex min-w-0 gap-x-4 p-2 items-center">
                    <div

                        className="h-[32px] min-w-[52px] border-2 border-dark"
                    >
                        <img
                            className="object-cover w-full h-full"
                            src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${countryOpportunity?.image}`}
                            alt={`country flag`}
                        />
                    </div>
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-bold leading-6 text-primary">{countryOpportunity?.name_ar}</p>
                    </div>
                </div>
            </td>
            <td className="py-2 px-4 group-hover:bg-muted/50">
                <div className="flex flex-wrap gap-2 items-start justify-start">
                    {countryOpportunity?.minerals.map((mineral) => {
                        return (
                            <div
                                key={`${mineral?.id}-${mineral?.id}`}
                                className="w-10 overflow-hidden border-primary"
                            >
                                <img
                                    title={mineral?.name_ar}
                                    className="object-cover"
                                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.image}`}
                                    alt={`${mineral?.name_ar}`}
                                />
                            </div>
                        );
                    })}
                </div>
            </td>
            <td className="py-2 px-4"><span>{countryOpportunity?.count}</span></td>
            <td className="py-2 px-4">
                <Link to={`${countryOpportunity?.id}`} ></Link>
                <div class="flex items-center justify-center gap-2">
                    <Link to={`countries/${countryOpportunity?.id}`} title="إضافة دولة" className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary" >
                        <IoMdAdd />
                    </Link>
                    <Link to={`countries/${countryOpportunity?.id}/source/edit`} className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary" >
                        <MdModeEdit />
                    </Link>
                </div>
            </td>
        </tr>


        {isOpenMenu &&
            <OpportunitiesTable opportunities={opportunities} countryId={countryOpportunity?.id} isLoading={opportunitiesIsLoading} />
        }




    </>)
}
const OpportunitiesTable = ({ opportunities, countryId, isLoading }) => {

    if (isLoading) {
        return (
            <tr className="bg-primary">
                <td className="bg-primary p-2" colSpan={5} >
                    <table className="w-full bg-muted">
                        <MineralsRegionTableLoading />

                    </table>

                </td>
            </tr>
        )
    }
    if (!opportunities || opportunities.length < 1) {
        return (
            <tr>
                <td colSpan={5} className="p-2 bg-primary">There is no opportunities</td>
            </tr>
        )
    }
    return (
        <tr className="bg-primary">
            <td className="bg-primary p-2" colSpan={5} >
                <table className="w-full bg-muted">
                    <thead className="bg-muted">
                        <tr>
                            <th className="border-b w-6"></th>
                            <th className="whitespace-nowrap py-2 px-4 text-start ">
                                العنوان
                            </th>
                            <th className="whitespace-nowrap py-2 px-4 text-start ">
                                نوع الفرصة
                            </th>
                            <th className="whitespace-nowrap py-2 px-4 text-start ">
                                الخام
                            </th>
                            <th className="whitespace-nowrap py-2 px-4 text-start ">
                                المساحة (كم2 )
                            </th>
                            <th className="whitespace-nowrap py-2 px-4 text-start ">
                                تاريخ الفرصة
                            </th>
                            <th className="border-b py-2 px-4 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {opportunities && opportunities.map((opportunity) => {
                            return (<>
                                <OpportunitiesTableRow opportunity={opportunity} countryId={countryId} />
                            </>)
                        })}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}

const OpportunitiesTableRow = ({ opportunity, countryId }) => {
    return (
        <tr
            id={`IdOpportunity${opportunity.id}`}
            key={opportunity.id}
            className="border-b"
        >
            <td className="border-muted border-b border-l">
                <div className="py-3 px-4  flex justify-center w-full h-full">
                    {opportunity.attachment_link ? (
                        <LuFileCheck
                            title="يتوفر على معلومات اضافية"
                            className="text-success"
                            size={22}
                        />
                    ) : (
                        <LuFileX
                            title="لا يتوفر على معلومات اضافية"
                            className="text-danger"
                            size={22}
                        />
                    )}
                </div>
            </td>
            <td className="py-3 px-4 border-muted border-b border-l">
                {opportunity.title_ar}
            </td>
            <td className="py-3 px-4 border-muted border-b border-l">
                {opportunity.type_ar}
            </td>
            <td className="py-3 px-4 border-muted border-b border-l">
                {opportunity.raw_materials_ar}
            </td>
            <td className="py-3 px-4 border-muted border-b border-l">
                {opportunity.area ? opportunity.area : "غير محددة"}
            </td>
            <td className="py-3 px-4 border-muted border-b border-l">
                {opportunity.started_at
                    ? moment(opportunity.started_at, "YYYY/MM/DD").year()
                    : "غير محدد"}
            </td>
            <td className="py-3 px-4 min-w-[145px] ">
                <div className="flex justify-center gap-2">
                    {opportunity.attachment_link && (
                        <Link
                            target="_blanck"
                            to={opportunity.attachment_link}
                            className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary"
                        >
                            <FaLink />
                        </Link>
                    )}

                    <Link
                        className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary"
                        to={`${opportunity.id}/countries/${countryId}/edit`}
                    >
                        <MdModeEdit />
                    </Link>

                    {/* <ConfirmToast
                      asModal={true}
                      customCancel={"إلغاء"}
                      customConfirm={"تأكيد"}
                      customFunction={() => handleOnDelete(opportunity.id)}
                      message={"هل أنت متأكد أنك تريد حذف هذه الفرصة؟"}
                      position={"top-left"}
                      showCloseIcon={false}
                    >
                      <button className="bg-danger/50 p-2 rounded-[6px] hover:bg-danger/80  text-white">
                        <FaTrash />
                      </button>
                    </ConfirmToast> */}
                </div>
            </td>
        </tr>
    )
}