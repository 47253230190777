import { TbCameraUp } from "react-icons/tb";


export default function MineralValueChainFormLoading  (){
    return (<>
    <div className="animate-pulse text-xl font-bold grid grid-rows-12 p-16">
        <div className="flex row-span-10 gap-8 ">
            <div className="flex-1  ">
                <div className="bg-muted flex items-center w-full h-[300px]">
                    <TbCameraUp className="w-full m-auto" size={40}/>
                </div>
                <br />
                <div className="p-2 bg-muted w-full"></div>
            </div>
            <div className="flex-1 ">
                <div className="bg-muted flex items-center w-full h-[300px]">
                    <TbCameraUp className="w-full m-auto" size={40}/>
                </div>
                <br />
                <div className="p-2 bg-muted w-full"></div>
            </div>
        </div>
        <br />
        <div className="">
            <div className="p-3 bg-muted w-full"></div>
        </div>

    </div>
    </>)
}