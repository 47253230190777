export default function MineralsTableLoading() {
    return (
        <>
            {[...Array(16)].map((_, index) =>
                <tr className="even:bg-white animate-pulse">
                    <td className="p-4">
                        <div className="flex items-center gap-4">
                            <div className="w-[30px] bg-muted h-[30px] rounded-full"></div>
                            <div className="grow">
                                <div className="bg-muted w-full p-1"></div>
                            </div>
                        </div>
                    </td>
                    <td className="p-4" colSpan={2}>
                        <div className="">
                            <div className="bg-muted w-full p-1"></div>
                        </div>
                    </td>

                    <td className="p-4">
                        <div className="">
                            <div className="bg-muted w-full p-1"></div>
                        </div>
                    </td>
                    <td className="p-4">
                        <div className="">
                            <div className="bg-muted w-full p-1"></div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    )
}