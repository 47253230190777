import { Navigate, useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useAuth } from "./contexts/authContext/AuthContext";
import { toast } from "react-toastify";

// Utility function to safely retrieve the value from a ref
const getRefValue = (ref) => (ref && ref.current ? ref.current.value : '');

// Utility function to safely retrieve the file from a ref
const getRefFile = (ref) => (ref && ref.current && ref.current.files ? ref.current.files[0] : null);
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scrolling
  });
};





const handleError = (error, authDispatch) => {
  const { response } = error;

  if (!response) {
    // Network error or no response received
    showToastError('حدث خطأ في الشبكة، يرجى المحاولة لاحقًا.');
    return;
  }

  const { status, data } = response;

  switch (status) {
    case 400:
      showToastError(data.message || 'طلب غير صالح.'); // Bad Request
      break;

    case 401:
      // Unauthorized: session expired or user is unauthenticated
      if (data.error === 'Unauthenticated.') {
        showToastError('انتهت صلاحية الجلسة الخاصة بك، يرجى تسجيل الدخول مرة أخرى.');
      } else {
        showToastError('أنت غير مصرح بالدخول.');
      }
      // authDispatch({ type: AUTH_ACTIONS.LOGOUT });
      <Navigate to={'/login'} />;
      break;

    case 403:
      showToastError('ليس لديك الصلاحية اللازمة لتنفيذ هذا الإجراء.'); // Forbidden
      break;

    case 404:
      showToastError('المورد الذي تحاول الوصول إليه غير موجود.'); // Not Found
      break;

    case 500:
      showToastError('حدث خطأ في الخادم، يرجى المحاولة لاحقًا.'); // Internal Server Error
      break;

    default:
      break;

  }

  // Optional: Log error to an error tracking service or console for debugging
  if (process.env.NODE_ENV === 'development') {
    console.error('API Error:', error);
  }
};


// Toast functions remain the same:
const showToastError = (message) => {
  toast.error(message, {
    position: 'top-left',
    duration: 5000,
    style: {
      borderRadius: '5px',
      color: '#ff6b6b',
      // color: '#fff',
      padding: '10px',
      fontSize: '14px',
    },
    iconTheme: {
      primary: '#ff6b6b',
      secondary: '#fff',
    },
  });
};

const showToastSuccess = (message) => {
  toast.success(message, {
    position: 'top-left',
    duration: 5000,
    style: {
      borderRadius: '5px',
      color: '#4CAF50',
      // color: '#fff',
      padding: '10px',
      fontSize: '14px',
    },
    iconTheme: {
      primary: '#4CAF50',
      secondary: '#fff',
    },
  });
};


const generateRandomPassword = () => {
  const length = 16; // Length of the password
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const getCategoryById = (id) => {
  if (id == 1) {
    return 'الطاقة النظيفة'
  }
  else if (id == 2) {
    return 'المعادن الصناعية'
  }
  else {
    return 'أسعار المعادن'
  }
}

export { getRefValue, getRefFile, scrollToTop, handleError, showToastError, showToastSuccess, generateRandomPassword, getCategoryById };