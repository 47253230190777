import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import axiosClient from "../../config/axios-client";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import { Editor } from "@tinymce/tinymce-react";
import editorConfig from "../../config/editorConfig";
import { TbCameraUp } from "react-icons/tb";
import MineralFormLoading from "../../components/ui/loadings/MineralFormLoading";
import { Link } from "react-router-dom";
import MineralValueChainFormLoading from "../../components/ui/loadings/MineralValueChainFormLoading";
import AddMineralValueChainPage from "./AddMineralValueChainPage";

export default function EditMineralValueChainPage() {

    const { mineralId } = useParams();

    //states
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [mineral, setMineral] = useState(null);
    const [isSending, setIsSending] = useState();

    const [selectedValueChainArImage, setSelectedValueChainArImage] = useState(null);
    const [selectedValueChainEnImage, setSelectedValueChainEnImage] = useState(null);

    //refs
    const valueChainArImageRef = useRef(null);
    const valueChainEnImageRef = useRef(null);

    const valueChainArSourceRef = useRef(null);
    const valueChainEnSourceRef = useRef(null);

    const valueChainLinkRef = useRef(null);


    //get mineral 
    const getMineral = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/minerals/${mineralId}`)
            .then(({ data }) => {
                setMineral(data.mineral);

                setSelectedValueChainArImage(`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.value_chain.image_ar}`)
                setSelectedValueChainEnImage(`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.value_chain.image_en}`)
                if (!data.mineral) {
                    navigate('/');
                }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })


    }
    const handleOnSubmitMineralUpdate = () => {
        setErrors({});
        const payload = {
            image_ar: valueChainArImageRef.current?.files[0] || "",
            image_en: valueChainEnImageRef.current?.files[0] || "",
            source_ar: getRefValue(valueChainArSourceRef),
            source_en: getRefValue(valueChainEnSourceRef),
            link: getRefValue(valueChainLinkRef),
        }

        axiosClient
            .post(`/dashboard/minerals/${mineralId}/value-chain`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                navigate('/minerals', {
                    replace: 'true'
                });

                showToastSuccess('تم تعديل بنجاح');
            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }



    const handleOnChangeValueChainAr = () => {
        if (valueChainArImageRef.current.files[0]) {
            setSelectedValueChainArImage(
                URL.createObjectURL(valueChainArImageRef.current.files[0])
            );
        }
    }

    const handleOnChangeValueChainEn = () => {
        if (valueChainEnImageRef.current.files[0]) {
            setSelectedValueChainEnImage(
                URL.createObjectURL(valueChainEnImageRef.current.files[0])
            );
        }
    }

    useEffect(() => {
        getMineral();
    }, [])

    if(!isLoading && !mineral.value_chain){
        <AddMineralValueChainPage/>
    }
    if (isLoading) {
        return (<>
            <MineralValueChainFormLoading />
        </>)
    }

    return (<>
        <div className='flex gap-0.5 font-bold mb-4'>
            <Link className='hover:text-secondary' to={'/minerals'} >
                المعادن
            </Link>
            <div>/</div>
            <div className='' to={`/minerals/${mineral && mineral.id}`} >
                {mineral && mineral.name_ar}
            </div>
            <div>/</div>
            <h2 className='text-info'>
                سلسلة القيمة
            </h2>
        </div>

        <div className="bg-white p-4 border-2">
            {/* images  */}
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
                <div className="h-[500px] w-full relative">
                    {selectedValueChainArImage || mineral?.value_chain?.image_ar
                        ?
                        <img className="border-2 bg-light p-2 h-full w-full " src={selectedValueChainArImage || `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.value_chain?.image_ar}`} alt={mineral?.name_en} />
                        :
                        <div className="border-2 bg-light p-2 h-full w-full flex items-center" >
                            <TbCameraUp strokeWidth={1.5} className="w-full m-auto" size={40} />
                        </div>
                    }
                    <div className='absolute bottom-0 left-0 right-0'>
                        <input onChange={handleOnChangeValueChainAr} ref={valueChainArImageRef} name="value_chain_image_ar" id="VALUE_CHAIN_IMAGE_AR"
                            type="file" className='hidden' />
                        <label htmlFor="VALUE_CHAIN_IMAGE_AR"
                            className={`flex w-full items-center justify-center text-primary bg-muted cursor-pointer hover:bg-secondary hover:text-white py-2  text-center`}
                        >
                            <span className="text-center m-auto">حدد صورة </span>
                        </label>
                    </div>
                </div>
                <div className="h-[500px] w-full relative">
                    {selectedValueChainEnImage || mineral?.value_chain?.image_en
                        ?
                        <img className="border-2 bg-light p-2 h-full w-full " src={selectedValueChainEnImage || `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.value_chain?.image_en}`} alt={mineral?.name_en} />
                        :
                        <div className="border-2 bg-light p-2 h-full w-full flex items-center" >
                            <TbCameraUp strokeWidth={1.5} className="w-full m-auto" size={40} />
                        </div>
                    }
                    <div className='absolute bottom-0 left-0 right-0'>
                        <input onChange={handleOnChangeValueChainEn} ref={valueChainEnImageRef} name="value_chain_image_en" id="VALUE_CHAIN_IMAGE_EN"
                            type="file" className='hidden' />
                        <label htmlFor="VALUE_CHAIN_IMAGE_EN"
                            className={`flex w-full items-center justify-center text-primary bg-muted cursor-pointer hover:bg-secondary hover:text-white py-2  text-center`}
                        >
                            <span className="text-center m-auto">حدد صورة </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
                <div className="flex flex-col gap-2 w-full">
                    <label htmlFor="SOURCE_AR" className="font-bold">اسم مصدر بالعربية</label>
                    <input ref={valueChainArSourceRef} defaultValue={mineral?.value_chain?.source_ar} className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_AR" id="SOURCE_AR" />
                </div>
                <div className="flex flex-col gap-2 w-full" >
                    <label htmlFor="SOURCE_EN" className="font-bold">اسم مصدر بالإنجليزية</label>
                    <input dir={"ltr"} ref={valueChainEnSourceRef} defaultValue={mineral?.value_chain?.source_en} className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_EN" id="SOURCE_EN" />
                </div>

            </div>
            <div className="flex flex-col gap-2 w-full mb-4">
                <label htmlFor="SOURCE_LINK" className="font-bold"> رابط المصدر </label>
                <input dir={"ltr"} ref={valueChainLinkRef} defaultValue={mineral?.value_chain?.link} className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_LINK" id="SOURCE_LINK" />
            </div>

            {/* submit  */}
            <div>
                <button
                    onClick={handleOnSubmitMineralUpdate}
                    className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold duration-150 ease-in-out transform"
                >
                    تعديل
                </button>
            </div>
        </div>

    </>)
}