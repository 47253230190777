import moment from "moment";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import DatePicker from "../../components/ui/DatePicker";
import Select from "react-tailwindcss-select";
import { Link } from "react-router-dom";
import { getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import axiosClient from "../../config/axios-client";


export default function AddOpportunitiesPage() {
    // variables 
    const { countryId } = useParams();
    const navigate = useNavigate();

    // states
    const [errors, setErrors] = useState();


    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState();
    const [selectedMinerals, setSelectedMinerals] = useState(null);
    const [minerals, setMinerals] = useState([]);
    const [mineralsIds, setMineralsIds] = useState("");
    const [country, setCountry] = useState(null);
    const [isMineralsLoading, setMineralsLoading] = useState(true);

    const [startedAtDate, setStartedAtDate] = useState(null);
    const [hasTime, setHasTime] = useState(false);
    const [hasArea, setHasArea] = useState(false);

    // refs
    const titleArRef = useRef(null);
    const titleEnRef = useRef(null);
    const titleFrRef = useRef(null);
    const mineralsArRef = useRef(null);
    const mineralsEnRef = useRef(null);
    const mineralsFrRef = useRef(null);
    const typeArRef = useRef(null);
    const typeEnRef = useRef(null);
    const typeFrRef = useRef(null);
    const areaRef = useRef(null);
    const attachmentLinkRef = useRef(null);
    const categoryRef = useRef(null);

    //function 
    const getCountry = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/countries/${countryId}`)
            .then(({ data }) => {
                setCountry(data.country);


                // if (!data.country) {
                //     navigate('/');
                // }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })
    };
    const getMinerals = () => {
        setMineralsLoading(true)
        axiosClient
            .get(`/dashboard/minerals`)
            .then(({ data }) => {
                setMinerals(data.minerals);


                // if (!data.country) {
                //     navigate('/');
                // }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })

    };
    const handleTimeCheckboxChange = (event) => {
        setHasTime(event.target.checked);
    };
    const handleAreaCheckboxChange = (event) => {
        setHasArea(event.target.checked);
        if (!event.target.checked) {
            areaRef.current = null;
        }
    };
    const handleOnChangeMinerals = (minerals) => {
        let text_ar = "";
        let text_fr = "";
        let text_en = "";
        let ids = [];
        if (minerals) {
            minerals.forEach((mineral, index) => {
                if (index == 0) {
                    text_ar += mineral.label;
                    if (mineral.name_en) {
                        text_en += mineral.name_en;
                    }
                    if (mineral.name_fr) {
                        text_fr += mineral.name_fr;
                    }
                } else {
                    text_ar += ` و${mineral.label}`;
                    if (mineral.name_en) {
                        text_en += `, ${mineral.name_en}`;
                    }
                    if (mineral.name_fr) {
                        text_fr += `, ${mineral.name_fr}`;
                    }
                }
            });
            minerals.forEach((mineral, index) => {
                ids.push(mineral.value);
            });

        }
        setSelectedMinerals(minerals);
        setMineralsIds(ids);
        mineralsArRef.current.value = text_ar;
        mineralsEnRef.current.value = text_en;
        mineralsFrRef.current.value = text_fr;
    };
    const handleOnChangeStartedAtDate = (date) => {
        setStartedAtDate(date);
    }
    const handleOnSubmit = () => {
        setErrors({});
        const payload = {
            category_id: getRefValue(categoryRef),
            title_ar: getRefValue(titleArRef),
            title_en: getRefValue(titleEnRef),
            title_fr: getRefValue(titleFrRef),
            type_ar: getRefValue(typeArRef),
            type_en: getRefValue(typeEnRef),
            type_fr: getRefValue(typeFrRef),
            raw_materials_ar: getRefValue(mineralsArRef),
            raw_materials_en: getRefValue(mineralsEnRef),
            raw_materials_fr: getRefValue(mineralsFrRef),
            area: getRefValue(areaRef),
            attachment_link: getRefValue(attachmentLinkRef),
            minerals_ids: mineralsIds,
            started_at: startedAtDate,
        }

        axiosClient
            .post(`/dashboard/countries/${countryId}/opportunities`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {

                if (data.opportunity) {
                    navigate(`/opportunities`, {
                        replace: 'true'
                    });
                    showToastSuccess('تم إنشاء الفرصة بنجاح.');
                }

            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }


    useEffect(() => {
        getMinerals()
        getCountry()
    }, [countryId])

    return (<>

        <div className='flex gap-0.5 font-bold mb-4'>
            <Link className='hover:text-secondary' to={'/opportunities'} >
                الفرص الاستثمارية
            </Link>
            <div>/</div>
            <h2 className='text-info'>
                تعديل فرصة
            </h2>
        </div>

        <div className="bg-white p-4 border-2 ">
            {country ?
                <div className="flex items-end gap-2 text-primary mb-4">
                    <img
                        className="h-[62px] min-w-[82px] object-cover border-2 border-dark"
                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                        alt={`${country.name_ar}`}
                    />
                    <div className="font-bold text-xl">{country.name_ar}</div>
                </div>
                :
                <div className="flex items-end gap-2 text-primary mb-4 animate-pulse ">
                    <div
                        className="h-[62px] min-w-[62px] bg-muted rounded-full"

                    />
                    <div className="font-bold text-xl p-2 w-[82px] bg-muted"> </div>
                </div>
            }

            <div className="flex flex-col gap-2 mb-4">
                <label className="font-bold"> الصنف </label>
                <select
                    ref={categoryRef}
                    className="outline-none border-2 border-muted py-1.5 px-4"
                    name="mineral_type"
                >
                    <option value="1">معادن الطاقة النظيفة</option>
                    <option value="2">معادن الصناعية</option>
                </select>
            </div>

            <div className="flex gap-4 w-full mb-4">
                <div dir="rtl" className="flex flex-col gap-2 w-full">
                    <label className="font-bold">عنوان بالعربية *</label>
                    <input
                        ref={titleArRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="text"
                        name="opportunity_title_ar"
                    />
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold">عنوان بالإنجليزية</label>
                    <input
                        dir="ltr"
                        ref={titleEnRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="text"
                        name="opportunity_title_en"
                    />
                </div>
            </div>

            <div className="flex flex-col gap-4 w-full mb-4">
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold">خامات </label>
                    <Select
                        value={selectedMinerals}
                        isMultiple
                        onChange={handleOnChangeMinerals}
                        options={minerals.map((item) => {
                            return {
                                label: item.name_ar,
                                value: item.id, // Assuming you want to keep the ID for some purpose
                                image: item.image,
                                name_en: item.name_en,
                                name_fr: item.name_fr,
                            };
                        })}
                        // loading={isCountriesLoading}
                        // isDisabled={isCountriesLoading}
                        isSearchable
                        placeholder="خامات"
                        isClearable
                        classNames={{
                            menuButton: ({ isDisabled }) =>
                                `outline-none border-2 border-muted px-4 bg-light flex rounded-none`, // Apply rounded-none here
                            searchContainer: "relative w-full",
                            searchBox: "w-full outline-none py-2 px-2 rounded-none border-2 border-muted", // Added rounded-none here as well
                            searchIcon: "absolute left-3 w-6 h-6 top-2.5",
                            menu: `absolute z-50 -mt-1 w-full bg-white shadow-lg border-2 border-muted rounded-none p-2`,
                        }}
                        formatOptionLabel={(data) => (
                            <li
                                dir="rtl"
                                className={`block z-50 text-primary transition duration-200 p-2 cursor-pointer select-none line-clamp-1  mt-1 ${data.isSelected
                                    ? `bg-muted font-bold`
                                    : ` font-semibold`
                                    }`}
                            >
                                <div className="flex items-center gap-4">
                                    <img
                                        className="w-12"
                                        src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${data.image}`}
                                        alt={data.label}
                                    />
                                    <div>{data.label}</div>
                                </div>
                            </li>
                        )}
                    />
                </div>
                <div className="flex gap-4">
                    <input
                        ref={mineralsArRef}
                        className="outline-none border-2 border-muted py-2 px-4 w-full"
                        type="text"
                        name="opportunity_mienrals_ar"
                    />
                    <input
                        dir="ltr"
                        ref={mineralsEnRef}
                        className="outline-none border-2 border-muted py-2 px-4 w-full"
                        type="text"
                        name="opportunity_mienrals_en"
                    />
                    <input
                        dir="ltr"
                        ref={mineralsFrRef}
                        className="outline-none border-2 border-muted py-2 px-4 w-full"
                        type="text"
                        name="opportunity_mienrals_fr"
                    />
                </div>
            </div>

            <div className="flex gap-4 py-4">
                <div dir="rtl" className="flex flex-col w-full gap-2">
                    <label className="font-bold">نوع بالعربية</label>
                    <input
                        ref={typeArRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="text"
                        name="opportunity_type_ar"
                    />
                </div>
                <div className="flex flex-col w-full gap-2">
                    <label className="font-bold">نوع بالإنجليزية</label>
                    <input
                        dir="ltr"
                        ref={typeEnRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="text"
                        name="opportunity_type_en"
                    />
                </div>
                <div className="flex flex-col w-full gap-2">
                    <label className="font-bold">نوع بالفرنسية</label>
                    <input
                        dir="ltr"
                        ref={typeFrRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="text"
                        name="opportunity_type_fr"
                    />
                </div>

            </div>
            <div className="flex flex-wrap gap-4 py-4">
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        <div className="flex items-end space-x-2 space-x-reverse">
                            <div>
                                المساحة (كم<sup>2</sup> )
                            </div>
                            <div>
                                <input type="checkbox" onChange={handleAreaCheckboxChange} />
                            </div>
                        </div>
                    </label>
                    {hasArea ? (
                        <input
                            ref={areaRef}
                            className="outline-none border-2 border-muted py-2 px-4"
                            min={0}
                            pattern="\d+"
                            type="number"
                            name="opportunity_area"
                        />
                    ) : (
                        <input
                            disabled
                            value={"غير محددة"}
                            type="text"
                            className="outline-none border-2 border-muted py-2 px-4"
                        />
                    )}
                </div>
                <div dir="rtl" className="flex grow flex-col  space-y-2">
                    <label className="font-bold">
                        <div className="flex items-end space-x-2 space-x-reverse">
                            <div>تاريخ الفرصة</div>
                            <div>
                                <input type="checkbox" onChange={handleTimeCheckboxChange} />
                            </div>
                        </div>
                    </label>
                    {hasTime ? (
                        <DatePicker defaultSelectedDate={moment()} getSelectedDate={handleOnChangeStartedAtDate} />
                    ) : (
                        <input
                            disabled
                            value={"غير محدد"}
                            type="text"
                            className="outline-none border-2 border-muted py-2 px-4"
                        />
                    )}
                </div>
                <div dir="rtl" className="flex grow min-w-full flex-col  space-y-2">
                    <label className="font-bold">رابط معلومات إضافية</label>
                    <input
                        dir="ltr"
                        placeholder="https://..."
                        ref={attachmentLinkRef}
                        className="outline-none border-2 border-muted py-2 px-4"
                        type="url"
                        name="opportunity_attachment_link"
                    />
                </div>
            </div>



            <div>
                <button
                    onClick={handleOnSubmit}
                    className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold"
                >
                    أضف
                </button>
            </div>
        </div>
    </>)
}
