import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import Home from "../pages/Home";
import MineralsPage from "../pages/minerals/MineralsPage";
import EditMineralPage from "../pages/minerals/EditMineralPage";
import EditMineralDescriptionPage from "../pages/minerals/EditMineralDescriptionPage";
import EditMineralCompoundsPage from "../pages/minerals/EditMineralCompoundsPage";
import EditMineralHistoryPage from "../pages/minerals/EditMineralHistoryPage";
import EditMineralSourcePage from "../pages/minerals/EditMineralSourcePage";
import EditMineralValueChainPage from "../pages/minerals/EditMineralValueChainPage";
import MineralsRegionPage from "../pages/minerals-region/MineralsRegionPage";
import EditMineralsRegionPage from "../pages/minerals-region/EditMineralsRegionPage";
import AddMineralsRegionPage from "../pages/minerals-region/AddMineralsRegionPage";
import OpportunitiesPage from "../pages/opportunities/OpportunitiesPage";
import AddOpportunitiesPage from "../pages/opportunities/AddOpportunitiesPage";
import EditOpportunitiesPage from "../pages/opportunities/EditOpportunitiesPage";
import EditOpportunitiesSourcePage from "../pages/opportunities/EditOpportunitiesSourcePage";
import LoginPage from "../pages/session/LoginPage";
import Splash from "../pages/Splash";
import AddMineralPage from "../pages/minerals/AddMineralPage";
import AddMineralValueChainPage from "../pages/minerals/AddMineralValueChainPage";
import CountriesUsersPage from "../pages/form/CountriesUsersPage";
import CountryUserPage from "../pages/form/CountryUserPage";

const router = createBrowserRouter([
  { 

    element:<Splash/>,
    children:[
              {
                path: "/",
                element: <DashboardLayout />,
                children: [
                  {
                    path: "/",
                    element: <Home />,
                  },
                  {
                    path: "minerals",
                    children:[
                      {
                        path: "",
                        element: <MineralsPage />,
                      },
                      {
                        path: "add",
                        element: <AddMineralPage />,
                      },
                      
                      {
                        path: ":mineralId",
                        children:[
                          {
                            path: "edit",
                            element: <EditMineralPage />,
                          },
                          {
                            path: "description/edit",
                            element: <EditMineralDescriptionPage/>,
                          },
                          {
                            path: "compounds/edit",
                            element: <EditMineralCompoundsPage/>,
                          },
                          {
                            path: "history/edit",
                            element: <EditMineralHistoryPage/>,
                          },
                          {
                            path: "source/edit",
                            element: <EditMineralSourcePage/>,
                          },
                          {
                            path: "value-chain/edit",
                            element: <EditMineralValueChainPage/>,
                          },
                          {
                            path: "value-chain/add",
                            element: <AddMineralValueChainPage />,
                          },
                        ]
                      },
                    ]
                  },
                  {
                    path: "minerals-region",
                    children:[
                        {
                          path:"",
                          element : <MineralsRegionPage />
          
                        },
                        {
                          path:":mineralId/edit",
                          element : <EditMineralsRegionPage />
                        },
                        {
                          path:":mineralId/add",
                          element : <AddMineralsRegionPage />
          
                        }
                    ]
                    
                  },
                  {
                    path: "opportunities",
                    children:[
                      { 
                        path:"",
                        element:<OpportunitiesPage/>
          
                      },
                      { 
                        path:"countries/:countryId",
                        element:<AddOpportunitiesPage/>
          
                      },
                      { 
                        path:"countries/:countryId/source/edit",
                        element:<EditOpportunitiesSourcePage/>
          
                      },
                      { 
                        path:":opportunityId/countries/:countryId/edit",
                        element:<EditOpportunitiesPage/>
          
                      },
                    ]
                  },
                  {
                    path : "form",
                    children:[
                        {
                          path:'',
                          element:<CountriesUsersPage/>
                        },
                        {
                          path:':userId',
                          element:<CountryUserPage/>
                        },

                    ]
                  }
                  
          
                ],
            },
            {
              path:'/session/login',
              element: <LoginPage/>
            }
    ]
  }
]);

export default router;
