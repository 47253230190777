import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import axiosClient from "../../config/axios-client";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import MineralFormLoading from "../../components/ui/loadings/MineralFormLoading";
import { Link } from "react-router-dom";

export default function EditMineralPage() {

    const { mineralId } = useParams();

    //states
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [mineral, setMineral] = useState(null);
    const [mineralImage, setMineralImage] = useState(null);
    const [isSending, setIsSending] = useState();

    //refs
    const nameArRef = useRef(null);
    const nameEnRef = useRef(null);
    const symbolRef = useRef(null);
    const categoryRef = useRef(null);
    const imageRef = useRef(null);
    const studiesLinkRef = useRef(null);

    //get mineral 
    const getMineral = () => {
        setIsLoading(true);
        setErrors({});
        axiosClient
            .get(`/dashboard/minerals/${mineralId}`)
            .then(({ data }) => {
                setMineral(data.mineral);

                setMineralImage(`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral.image}`);
                if (!data.mineral) {
                    navigate('/');
                }



            })
            .catch((error) => {
                console.log(error);

                // setErrors(err)
            }).finally(() => {
                setIsLoading(false);
            })


    }
    const handleOnSubmitMineralUpdate = () => {
        setErrors({});
        const payload = {
            name_ar: getRefValue(nameArRef),
            name_en: getRefValue(nameEnRef),
            symbol: getRefValue(symbolRef),
            image: getRefFile(imageRef),
            category_id: getRefValue(categoryRef),
            studies_link: getRefValue(studiesLinkRef),
        }

        axiosClient
            .post(`/dashboard/minerals/${mineralId}/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                navigate('/minerals', {
                    replace: 'true'
                });
                showToastSuccess('تم تعديل بنجاح');
            })
            .catch((error) => {
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    }

    const handleOnChangeImageInput = () => {
        setMineralImage(URL.createObjectURL(imageRef.current.files[0]));
    };

    useEffect(() => {
        getMineral();
    }, [])

    if (isLoading) {
        return (<>
            <MineralFormLoading />
        </>)
    }
    return (<>
        <div className='flex gap-0.5 text-sm font-bold mb-4'>
            <Link className='hover:text-secondary' to={'/minerals'} >
                المعادن
            </Link>
            <div>/</div>
            <div className='--hover:text-secondary cursor-default' to={`/minerals/${mineral && mineral.id}`} >
                {mineral && mineral.name_ar}
            </div>
            <div>/</div>
            <div className='text-info'>
                تعديل معلومات المعدن
            </div>
        </div>
        <div className="bg-white p-4 border-2">
            {/* image  */}
            <div className="max-w-[200px] mb-4 relative">
                <img className="border-2 border-muted h-[200px] w-full bg-white p-2" src={mineralImage || `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.image}`} alt={mineral?.name_en} />
                <div className='absolute bottom-0 left-0 right-0'>
                    <input onChange={handleOnChangeImageInput} ref={imageRef} name="image" id="IMAGE" type="file" className='hidden' accept=".webp" />
                    <label htmlFor="IMAGE" className={`flex w-full items-center justify-center text-primary bg-muted cursor-pointer hover:bg-secondary hover:text-white py-2  text-center`} >
                        <div className="text-sm">حدد صورة </div>
                    </label>
                </div>
            </div>

            {/* informations */}
            <div className="">

                <div className="flex flex-col sm:flex-row gap-4 w-full mb-4">
                    <div className="flex flex-col gap-2 w-1/2 ">
                        <label className="font-bold" htmlFor="MINERAL_NAME_AR">اسم معدن بالعربية *</label>
                        <input ref={nameArRef} defaultValue={mineral?.name_ar} className="outline-none border-2 border-muted py-2 px-4" type="text" name="MINERAL_NAME_AR" id="MINERAL_NAME_AR" />
                    </div>
                    <div className="flex flex-col gap-2 w-1/2">
                        <label className="font-bold" htmlFor="MINERAL_NAME_EN">اسم معدن بالإنجليزية *</label>
                        <input ref={nameEnRef} defaultValue={mineral?.name_en} className="outline-none border-2 border-muted py-2 px-4" type="text" name="MINERAL_NAME_EN" id="MINERAL_NAME_EN" />
                    </div>
                </div>


                <div className="flex flex-col sm:flex-row gap-4 w-full mb-4">
                    <div className="flex flex-col gap-2 w-1/2 ">
                        <label className="font-bold" htmlFor="SYMBOL">رمز معدن *</label>
                        <input ref={symbolRef} defaultValue={mineral?.symbol} className="outline-none border-2 border-muted py-2 px-4" type="text" name="SYMBOL" id="SYMBOL" />
                    </div>
                    <div className="flex flex-col gap-2 w-1/2 ">
                        <label className="font-bold" htmlFor="CATEGORY_ID">نوع *</label>
                        <select
                            className="outline-none border-2 border-muted py-1 px-4"
                            name="CATEGORY_ID"
                            id="CATEGORY_ID"
                            ref={categoryRef}
                            defaultValue={mineral?.category_id}
                        >
                            <option value="1">طاقة نظيفة </option>
                            <option value="2" selected={mineral?.category_id == 2}> صناعية </option>
                            <option value="3" selected={mineral?.category_id == 3}>أسعار المعادن  </option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <label className="font-bold" htmlFor="STUDIES_LINK">رابط الدراسات</label>
                    <input dir="ltr" ref={studiesLinkRef} defaultValue={mineral?.studies_link} className="outline-none border-2 border-muted py-2 px-4" type="url" name="STUDIES_LINK" id="STUDIES_LINK" />
                </div>
            </div>

            {/* submit  */}
            <div className="mt-4">
                <button
                    onClick={handleOnSubmitMineralUpdate}
                    className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold duration-150 ease-in-out transform"
                >
                    تعديل
                </button>
            </div>

        </div>
    </>)
}
