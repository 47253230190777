import { useEffect, useState } from "react";
import axiosClient from "../../config/axios-client";
import MineralsTableLoading from "../../components/ui/loadings/MineralsTableLoading";
import { Link } from "react-router-dom";
import { PiFilesFill } from "react-icons/pi";
import moment from 'moment';
import 'moment/locale/ar-kw'; // Import Arabic locale
moment.locale('ar-kw');


export default function CountriesUsersPage (){
    
    const [countriesUsers, setCountriesUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getcountriesUsers = async () => {
        setIsLoading(true);

        axiosClient
            .get(`/dashboard/form/countries_users`)
            .then(({ data }) => {
                setCountriesUsers(data.countries_users);
            })
            .catch((err) => {

            }).finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getcountriesUsers();
    }, []);

    return (<>
        <div className="">
            <div className="flex justify-between mb-4">
                <div>
                    <h2 className='text-primary font-bold text-lg'>
                        بيانات المعادن بالمنطقة العربية
                    </h2>
                </div>

                
            </div>

            <div className="mb-64">
                
                <table className="w-full">
                    <thead>
                        <tr className="text-white text-center border-2 border-primary bg-primary ">
                            <th className="p-3 px-4 text-start">الدولة</th>
                            <th className="p-3 px-4 text-right">الجهة المعنية</th>
                            <th className="p-3 px-4 text-right">الأسم الكامل  </th>
                            <th className="p-3 px-4 text-right" dir="">التاريخ</th>
                            <th className="p-3 px-4 text-center">عدد الملفات</th>
                            <th className="p-3 px-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {countriesUsers && countriesUsers.map((user)=>{
                            return (
                                <tr className="text-center group odd:bg-white border-2 border-top-none bg-light">
                                    <td className="p-2 px-4 group-hover:bg-muted/50 text-start">{user?.country?.name_ar}</td>
                                    <td className="p-2 px-4 group-hover:bg-muted/50 text-right">{user?.title}</td>
                                    <td className="p-2 px-4 group-hover:bg-muted/50 text-right">{user?.full_name}</td>
                                    <td className="p-2 px-4 group-hover:bg-muted/50 text-right">{moment(user?.created_at).format('LL')}</td>
                                    <td className="p-2 px-4 group-hover:bg-muted/50 text-center">{user?.files?.length}</td>
                                    <td className="p-2 px-4 group-hover:bg-muted/50">                                        
                                        <Link to={`${user.id}`} className="relative inline-flex items-center bg-light border-2 border-muted text-primary p-2 group-hover:bg-muted w-fit m-auto text-center">
                                            <PiFilesFill size={22}/>                                          
                                        </Link>                                       
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        
    </>)
}

