import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import { getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import { Link } from "react-router-dom";
import axiosClient from "../../config/axios-client";
import { TbCameraUp } from "react-icons/tb";
export default function AddMineralValueChainPage() {

    const { mineralId } = useParams();
    //states
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();
    const [mineral, setMineral] = useState(null);
    const [isSending, setIsSending] = useState();

    const [selectedValueChainArImage, setSelectedValueChainArImage] = useState(null);
    const [selectedValueChainEnImage, setSelectedValueChainEnImage] = useState(null);


    //description
    const valueChainArImageRef = useRef(null);
    const valueChainEnImageRef = useRef(null);

    const valueChainArSourceRef = useRef(null);
    const valueChainEnSourceRef = useRef(null);

    const valueChainLinkRef = useRef(null);


    const handleOnChangeValueChainAr = () => {
        setSelectedValueChainArImage(
            URL.createObjectURL(valueChainArImageRef.current.files[0])
        );
    }

    const handleOnChangeValueChainEn = () => {
        setSelectedValueChainEnImage(
            URL.createObjectURL(valueChainEnImageRef.current.files[0])
        );
    }

    const handleOnSubmitMineralValueChainAdd = () => {
        setErrors({});
        const payload = {

            image_ar: valueChainArImageRef.current?.files[0] || "",
            image_en: valueChainEnImageRef.current?.files[0] || "",
            source_ar: getRefValue(valueChainArSourceRef),
            source_en: getRefValue(valueChainEnSourceRef),
            link: getRefValue(valueChainLinkRef),
        }

        axiosClient
            .post(`/dashboard/minerals/${mineralId}/value-chain/add`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                if (data.value_chain) {
                    navigate('/minerals', {
                        replace: 'true'
                    });
                    showToastSuccess('تم إنشاء سلسلة القيمة بنجاح');
                }

            })
            .catch((error) => {
                showToastError('حدث خطأ أثناء إنشاء سلسلة القيمة')
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.errors) {
                        setErrors(data.errors);
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }
            })
            .finally(() => {
                setIsSending(false);
            });


    }



    //async
    return (<>
        <div className="">
            <div className='flex gap-0.5 text-xs font-bold mb-5'>
                <div>/</div>
                <Link className='' to={'/minerals'} >
                    المعادن
                </Link>

                <div>/</div>
                <h2 className='text-warning'>
                    اضافة سلسلة القيمة
                </h2>
            </div>

            <div className="text-primary col-span-12 py-2 px-4 text-2xl font-bold my-5">
                اضافة سلسلة القيمة
            </div>


            {/* <div className="flex flex-wrap gap-4">
                <div className="flex-1 h-50 p-4 bg-white border">
                    {selectedValueChainArImage || mineral?.value_chain?.image_ar
                        ?
                        <img className="border bg-light p-2 w-full min-w-[250px] h-[200px]" src={selectedValueChainArImage || `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.value_chain?.image_ar}`} alt={mineral?.name_en} />
                        :
                        <div className="border bg-light p-2 w-full min-w-[250px] h-[200px] flex items-center" >
                            <TbCameraUp className="w-full m-auto" size={40} />

                        </div>
                    }
                    <div className='flex  justify-center my-5'>
                        <input onChange={handleOnChangeValueChainAr} ref={valueChainArImageRef} name="source_image" id="VALUE_CHAIN_IMAGE_AR"
                            type="file" className='bg-light py-2.5 px-6 outline-none w-fit hidden' />
                        <label htmlFor="VALUE_CHAIN_IMAGE_AR"
                            className={`grow  bg-success items-center cursor-pointer hover:bg-success flex px-[16px] py-[8px] text-white text-center`}
                        >
                            <span className="text-center m-auto">حدد صورة </span>
                        </label>
                    </div>
                </div>

                <div className="flex-1 h-50 p-4 bg-white border">

                    {selectedValueChainEnImage || mineral?.value_chain?.image_en
                        ?
                        <img className="border bg-light p-2 w-full min-w-[250px] h-[200px]" src={selectedValueChainEnImage || `${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.value_chain?.image_en}`} alt={mineral?.name_en} />
                        :
                        <div className="border bg-light p-2 w-full min-w-[250px] h-[200px] flex items-center" >
                            <TbCameraUp className="w-full m-auto" size={40} />

                        </div>
                    }
                    <div className='flex  justify-center my-5'>
                        <input onChange={handleOnChangeValueChainEn} ref={valueChainEnImageRef} name="source_image" id="VALUE_CHAIN_IMAGE_EN"
                            type="file" className='bg-light py-2.5 px-6 outline-none w-fit hidden' />
                        <label htmlFor="VALUE_CHAIN_IMAGE_EN"
                            className={`grow  bg-success items-center cursor-pointer hover:bg-success flex px-[16px] py-[8px] text-white text-center`}
                        >
                            <span className="text-center m-auto">حدد صورة </span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="my-5">
                <div className="flex gap-4 flex-wrap ">
                    <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                        <label className="font-bold" htmlFor="SOURCE_AR">اسم مصدر بالعربية</label>
                        <input ref={valueChainArSourceRef} defaultValue={mineral?.value_chain?.source_ar} className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="SOURCE_AR" id="SOURCE_AR" />
                    </div>
                    <div className="flex flex-col gap-2 w-full sm:w-1/3 grow" >
                        <label className="font-bold" htmlFor="SOURCE_EN">اسم مصدر بالإنجليزية</label>
                        <input dir={"ltr"} ref={valueChainEnSourceRef} defaultValue={mineral?.value_chain?.source_en} className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="SOURCE_EN" id="SOURCE_EN" />
                    </div>

                </div>
                <div className="flex gap-4 flex-wrap ">
                    <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                        <label className="font-bold" htmlFor="SOURCE_LINK"> رابط المصدر </label>
                        <input dir={"ltr"} ref={valueChainLinkRef} defaultValue={mineral?.value_chain?.link} className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="SOURCE_LINK" id="SOURCE_LINK" />
                    </div>
                </div>

            </div> */}



            {/* <div className="my-5">
                <button
                    onClick={handleOnSubmitMineralValueChainAdd}
                    className="py-3 px-6 bg-primary hover:bg-primary/75 w-full text-white font-bold duration-150 ease-in-out transform"
                >
                    تعديل
                </button>
            </div> */}

            <div className="bg-white p-4 border-2">
                {/* images  */}
                <div className="flex flex-col sm:flex-row gap-4 mb-4">
                    <div className="h-[500px] w-full relative">
                        {selectedValueChainArImage 
                            ?
                            <img className="border-2 bg-light p-2 h-full w-full " src={selectedValueChainArImage} alt={mineral?.name_en} />
                            :
                            <div className="border-2 bg-light p-2 h-full w-full flex items-center" >
                                <TbCameraUp strokeWidth={1.5} className="w-full m-auto" size={40} />
                            </div>
                        }
                        <div className='absolute bottom-0 left-0 right-0'>
                            <input onChange={handleOnChangeValueChainAr} ref={valueChainArImageRef} name="value_chain_image_ar" id="VALUE_CHAIN_IMAGE_AR"
                                type="file" className='hidden' />
                            <label htmlFor="VALUE_CHAIN_IMAGE_AR"
                                className={`flex w-full items-center justify-center text-primary bg-muted cursor-pointer hover:bg-secondary hover:text-white py-2  text-center`}
                            >
                                <span className="text-center m-auto">حدد صورة </span>
                            </label>
                        </div>
                    </div>
                    <div className="h-[500px] w-full relative">
                        {selectedValueChainEnImage 
                            ?
                            <img className="border-2 bg-light p-2 h-full w-full " src={selectedValueChainEnImage} alt={mineral?.name_en} />
                            :
                            <div className="border-2 bg-light p-2 h-full w-full flex items-center" >
                                <TbCameraUp strokeWidth={1.5} className="w-full m-auto" size={40} />
                            </div>
                        }
                        <div className='absolute bottom-0 left-0 right-0'>
                            <input onChange={handleOnChangeValueChainEn} ref={valueChainEnImageRef} name="value_chain_image_en" id="VALUE_CHAIN_IMAGE_EN"
                                type="file" className='hidden' />
                            <label htmlFor="VALUE_CHAIN_IMAGE_EN"
                                className={`flex w-full items-center justify-center text-primary bg-muted cursor-pointer hover:bg-secondary hover:text-white py-2  text-center`}
                            >
                                <span className="text-center m-auto">حدد صورة </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-4 mb-4">
                    <div className="flex flex-col gap-2 w-full">
                        <label htmlFor="SOURCE_AR" className="font-bold">اسم مصدر بالعربية</label>
                        <input ref={valueChainArSourceRef}  className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_AR" id="SOURCE_AR" />
                    </div>
                    <div className="flex flex-col gap-2 w-full" >
                        <label htmlFor="SOURCE_EN" className="font-bold">اسم مصدر بالإنجليزية</label>
                        <input dir={"ltr"} ref={valueChainEnSourceRef}  className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_EN" id="SOURCE_EN" />
                    </div>

                </div>
                <div className="flex flex-col gap-2 w-full mb-4">
                    <label htmlFor="SOURCE_LINK" className="font-bold"> رابط المصدر </label>
                    <input dir={"ltr"} ref={valueChainLinkRef}  className="outline-none border-2 border-muted py-2 px-4" type="text" name="SOURCE_LINK" id="SOURCE_LINK" />
                </div>

                {/* submit  */}
                <div>
                    <button
                        onClick={handleOnSubmitMineralValueChainAdd}
                        className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold duration-150 ease-in-out transform"
                    >
                        تعديل
                    </button>
                </div>
            </div>
        </div>
    </>)
}
