import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';  // Import Flatpickr styles
import { Arabic } from 'flatpickr/dist/l10n/ar.js'; // Import Arabic locale
import moment from 'moment';

const DatePicker = ({ getSelectedDate, defaultSelectedDate }) => {

  const defaultDate = defaultSelectedDate || moment('Y-M-D');
  const handleSelectedDate = (selectedDate) => {
    const formatedDate = moment(selectedDate, "D-M-Y").format("Y-M-D");
    console.log(formatedDate);
    getSelectedDate(formatedDate)
  }
  return (
    <>
      <Flatpickr
        defaultValue={moment(defaultDate, "Y-M-D").format("DD-MM-YYYY")}
        onChange={([selectedDate]) => handleSelectedDate(selectedDate)}  // Update state on change
        options={{
          locale: Arabic,
          dateFormat: "d-m-Y",
          defaultDate: moment(defaultDate, "Y-M-D").format("DD-MM-YYYY")
        }}
        className='outline-none border border-muted py-2 px-6'
        placeholder='dd-mm-YYYY'
      />
    </ >
  );
};

export default DatePicker;