import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import { Link } from "react-router-dom";
import axiosClient from "../../config/axios-client";
import { TbCameraUp } from "react-icons/tb";
import { Editor } from "@tinymce/tinymce-react";
import editorConfig from "../../config/editorConfig";
export default function AddMineralPage(){
    
    //states
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [mineral, setMineral] = useState(null);
    const [mineralImage, setMineralImage] = useState(null);
    const [isSending,setIsSending] = useState();
    const [sourceImage, setSourceImage] = useState(null);
 

    const [sectionDescription,setSectionDescription] = useState(true);
    const [sectionCompound,setSectionCompound] = useState(true);
    const [sectionHistory,setSectionHistory] = useState(true);
    const [sectionSource,setSectionSource] = useState(true);
    
    //refs
    const nameArRef = useRef(null);
    const nameEnRef = useRef(null);
    const nameFrRef = useRef(null);
    const symbolRef = useRef(null);
    const categoryRef = useRef(null);
    const imageRef = useRef(null);
    const studiesLinkRef = useRef(null);
    
    //description
    const descriptionBodyArRef = useRef('');
    const descriptionBodyEnRef = useRef('');
    // const descriptionBodyFrRef = useRef('');
    // //history
    const historyBodyArRef = useRef('');
    const historyBodyEnRef = useRef('');
    // const historyBodyFrRef = useRef('');
    // //source
    const sourceBodyArRef = useRef('');
    const sourceBodyEnRef = useRef('');
    // const sourceBodyFrRef = useRef('');
    const sourceImageRef = useRef(null);
    //compound 
    const compoundBodyArRef = useRef('');
    const compoundBodyEnRef = useRef('');
    const compoundBodyFrRef = useRef('');
    

    //functions
    const handleOnChangeImageInput = () => {
        setMineralImage(URL.createObjectURL(imageRef.current.files[0]));
    };
    const handleOnChangeImageSourceInput = () => {
        setSourceImage(URL.createObjectURL(sourceImageRef.current.files[0]));
    };

   

    const handleOnSubmitMineralAdd = ()=>{
        setErrors({});
        const payload = {
            name_ar: getRefValue(nameArRef),
            name_en: getRefValue(nameEnRef),
            name_fr: getRefValue(nameFrRef),
            symbol: getRefValue(symbolRef),
            image: getRefFile(imageRef),
            category_id: getRefValue(categoryRef),
            studies_link:getRefValue(studiesLinkRef),

            description_ar: descriptionBodyArRef.current.getContent(),
            description_en: descriptionBodyEnRef.current.getContent(),
            // description_fr: descriptionBodyFrRef.current.getContent(),

            history_description_ar: historyBodyArRef.current.getContent(),
            history_description_en: historyBodyEnRef.current.getContent(),
            // history_description_fr: historyBodyFrRef.current.getContent(),

            source_description_ar: sourceBodyArRef.current.getContent(),
            source_description_en: sourceBodyEnRef.current.getContent(),
            // source_description_fr: sourceBodyFrRef.current.getContent(),
            source_image: getRefFile(sourceImageRef),

           
        }

        axiosClient
            .post(`/dashboard/minerals/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                if(data.mineral){
                    navigate('/minerals', {
                        replace: 'true'
                    });
                    showToastSuccess('تم إنشاء المعدن بنجاح')
                }
            })
            .catch((error) => {
                
                scrollToTop();
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.error) {
                        setErrors(data.error);
                        showToastError(data.error)
                    } else {
                        console.error('Unexpected error format:', data);
                        setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received:', error.request);
                    setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
                } else {
                    // Something else happened while setting up the request
                    console.error('Error setting up request:', error.message);
                    setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
                }

            })
            .finally(() => {
                setIsSending(false);
            });

        
    }
    
    
    
    //async
    return (<>
    <div className="">
        <div className='flex gap-0.5 text-xs font-bold mb-5'>
            <div>/</div>
            <Link className='' to={'/minerals'} >
              المعادن  
            </Link>
           
            <div>/</div>
            <h2 className='text-warning'>
                اضافة معدن
            </h2>
        </div>
        
        <div className="text-primary col-span-12 py-2 px-4 text-2xl font-bold my-5">
        اضافة معدن
        </div>
        <div className="bg-danger/50">
        </div>
        {/* add mineral photo */}
        <div className="w-fit">
            {!mineralImage ?
                <div className="w-[150px] h-[150px] flex items-center bg-muted ">
                 <TbCameraUp className="w-full m-auto" size={40}/>
            </div>
            :
            <img className="border max-w-[150px] h-auto w-full  bg-white p-2" src={mineralImage} />
            }
            <div className='flex  justify-center my-5 '>
                <input onChange={handleOnChangeImageInput} ref={imageRef} name="image" id="IMAGE" accept="image/webp"
                    type="file" className='bg-light py-2.5 px-6 rounded-lg outline-none  w-fit hidden' />
                <label htmlFor="IMAGE"
                    className={`grow  bg-success items-center cursor-pointer hover:bg-success flex px-[16px] py-[8px] text-white text-center`}
                >
                    <span className="text-center m-auto">حدد صورة <span className="text-danger">*</span></span>
                </label>
            </div>
        </div>

        {/* informations about mineral*/}
        <div className="">
            
            <div className="flex gap-4 flex-wrap ">
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="MINERAL_NAME_AR">اسم معدن بالعربية<span className="text-danger">*</span></label>
                    <input ref={nameArRef}  className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="MINERAL_NAME_AR" id="MINERAL_NAME_AR"/>
                </div>
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="MINERAL_NAME_EN">اسم معدن بالإنجليزية<span className="text-danger">*</span></label>
                    <input ref={nameEnRef}  className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="MINERAL_NAME_EN" id="MINERAL_NAME_EN"/>
                </div>
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="MINERAL_NAME_FR">اسم معدن بالفرنسية</label>
                    <input ref={nameFrRef}  className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="MINERAL_NAME_FR" id="MINERAL_NAME_FR"/>
                </div>
            </div>


            <div className="flex gap-4 flex-wrap ">
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="SYMBOL">رمز معدن</label>
                    <input ref={symbolRef}  className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="SYMBOL" id="SYMBOL"/>
                </div>
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="CATEGORY_ID">نوع <span className="text-danger">*</span></label>
                    <select
                        className="outline-none border bg-white focus:bg-white py-2 px-6 appearance-none"
                        name="CATEGORY_ID"
                        id="CATEGORY_ID"
                        ref={categoryRef}
                        >
                        <option value="1">طاقة نظيفة</option>
                        <option value="2">صناعية </option>
                        <option value="3"> أسعار معادن </option>
                    </select>
                </div>
                <div className="flex flex-col gap-2 w-full sm:w-1/3 grow">
                    <label className="font-bold" htmlFor="STUDIES_LINK">رابط الدراسات</label>
                    <input ref={studiesLinkRef}  className="outline-none border bg-white focus:bg-white py-2 px-6" type="text" name="STUDIES_LINK" id="STUDIES_LINK"/>
                </div>
            </div>

            
        </div>
        {/* description */}
        <div className="">
            <div className="bg-primary text-white col-span-12 py-2 px-4  text-2xl font-bold my-5">
                تعديل الوصف    
            </div>
            <div className="">
            
                {sectionDescription && 
                <>
                    <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالعربية *</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (descriptionBodyArRef.current = editor)}
                        />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالإنجليزية</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (descriptionBodyEnRef.current = editor)}
                        />
                        </div>
                    </div>
                    {/* <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالفرنسية</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (descriptionBodyFrRef.current = editor)}
                        />
                        </div>
                    </div> */}
                </>
                }
                
            </div>

        </div>
        {/* history */}
        <div className="">
                    
            <div className="bg-primary text-white col-span-12 py-2 px-4 text-2xl font-bold my-5">
            تعديل التاريخ        
            </div>
            
                {sectionHistory && 
                    <>
                    <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالعربية *</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (historyBodyArRef.current = editor)}
                        />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالإنجليزية</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (historyBodyEnRef.current = editor)}
                        />
                        </div>
                    </div>
                    {/* <div className="flex flex-col space-y-2 col-span-12">
                        <label className="font-bold">بالفرنسية</label>
                        <div>
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_KEY}
                            init={editorConfig}
                            onInit={(evt, editor) => (historyBodyFrRef.current = editor)}
                        />
                        </div>
                    </div> */}
                    </>
                }
        
                
        </div>

        {/* Source */}
        <div className="">
            <div className="bg-primary text-white col-span-12 py-2 px-4 text-2xl font-bold my-5">
                تعديل المصدر        
            </div>
            {sectionSource && 
            <>
                <div className="w-fit">
                {!sourceImage ?
                <div className="w-[150px] h-[150px] flex items-center bg-muted ">
                 <TbCameraUp className="w-full m-auto" size={40}/>
            </div>
            :
            <img className="border max-w-[150px] h-auto w-full  bg-white p-2" src={sourceImage} />
            }
                <div className='flex  justify-center my-5'>
                    <input onChange={handleOnChangeImageSourceInput} ref={sourceImageRef} name="source_image" id="SOURCE_IMAGE"
                        type="file" className='bg-light py-2.5 px-6 outline-none w-fit hidden' />
                    <label htmlFor="SOURCE_IMAGE"
                        className={`grow  bg-success items-center cursor-pointer hover:bg-success flex px-[16px] py-[8px] text-white text-center`}
                    >
                        <span className="text-center m-auto">حدد صورة </span>
                    </label>
                </div>
            </div>

            <div className="">
                <div className="flex flex-col space-y-2 col-span-12 mb-5">
                    <label className="font-bold">بالعربية *</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (sourceBodyArRef.current = editor)}
                    />
                    </div>
                </div>
                <div className="flex flex-col space-y-2 col-span-12 mb-5">
                    <label className="font-bold">بالإنجليزية</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (sourceBodyEnRef.current = editor)}
                    />
                    </div>
                </div>
                {/* <div className="flex flex-col space-y-2 col-span-12 mb-5">
                    <label className="font-bold">بالفرنسية</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (bodyFrRef.current = editor)}
                    />
                    </div>
                </div> */}
                
            </div>
            </>
            }
        </div>
        {/* compounds */}
        <div className="">
            
            <div className="bg-primary text-white col-span-12 py-2 px-4 text-2xl font-bold my-5">
                تعديل المركبات
            </div>
            {sectionCompound && 
            <>
                <div className="flex flex-col space-y-2 col-span-12">
                    <label className="font-bold">بالعربية *</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (compoundBodyArRef.current = editor)}
                    />
                    </div>
                </div>
                <div className="flex flex-col space-y-2 col-span-12">
                    <label className="font-bold">بالإنجليزية</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (compoundBodyEnRef.current = editor)}
                    />
                    </div>
                </div>
                {/* <div className="flex flex-col space-y-2 col-span-12">
                    <label className="font-bold">بالفرنسية</label>
                    <div>
                    <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        init={editorConfig}
                        onInit={(evt, editor) => (compoundBodyFrRef.current = editor)}
                    />
                    </div>
                </div> */}
            </>
            }
                
        </div>

        

        <div className="my-5">
            <button
            onClick={handleOnSubmitMineralAdd}
            className="py-3 px-6 bg-primary hover:bg-primary/75 w-full text-white font-bold duration-150 ease-in-out transform"
            >
            إضافة
            </button>
        </div>
    </div>
    </>)
}
