import { useEffect, useState } from "react";
import axiosClient from "../../config/axios-client";
import { Link } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { min } from "moment/moment";
import { getCategoryById } from "../../helper";
import { GrNext } from "react-icons/gr";
import MineralsTableLoading from "../../components/ui/loadings/MineralsTableLoading";
import { IoEyeSharp } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";

export default function MineralsPage() {
    //const location = useLocation();
    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getMinerals = async () => {
        setIsLoading(true);

        axiosClient
            .get(`/dashboard/minerals`)
            .then(({ data }) => {
                setMinerals(data.minerals);
            })
            .catch((err) => {

            }).finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getMinerals();
    }, []);

    return (<>
        <div className="">
            <div className="flex justify-between mb-4">
                <div>
                    <h2 className='text-primary font-bold text-lg'>
                        المعادن
                    </h2>
                </div>

                <Link to={'/minerals/add'} className="bg-primary py-2 px-4 hover:bg-success flex items-center gap-4 text-white">
                    إضافة معدن
                </Link>
            </div>

            <div className="mb-64">
                <table className="w-full text-right">
                    <thead className="text-white text-center border-2 border-primary bg-primary ">
                        <th className="p-3 px-4">المعدن</th>
                        <th className="p-3 px-4">رمز	</th>
                        <th className="p-3 px-4">النوع	</th>
                        <th className="p-3 px-4">رابط الدراسات	</th>
                        <th className="p-3 px-4">Actions </th>
                    </thead>

                    {!isLoading
                        ?
                        <tbody>
                            {minerals?.map((mineral) => <MineralsTableItem mineral={mineral} />)}
                        </tbody>
                        :
                        <MineralsTableLoading />
                    }

                </table>
            </div>
        </div>


    </>)
}



const MineralsTableItem = ({ mineral }) => {

    const [isOpenMenu, setIsOpenMenu] = useState();

    return <tr className="text-center odd:bg-white border-2 border-top-none bg-light">
        <td className="text-start">
            <div className="flex gap-4 p-2">
                <img alt={mineral?.name_ar} src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.image}`} className="h-12 w-12" />
                <div className="">
                    <div className="font-bold text-primary">{mineral?.name_ar}</div>
                    <div className="line-clamp-1 text-primary">{mineral?.name_en}</div>
                </div>
            </div>
        </td>
        <td className=""><span>{mineral.symbol}</span></td>
        <td className=""><span>{getCategoryById(mineral.category_id)}</span></td>
        <td className=""><span><GetCheckedIcon checked={mineral?.studies_link ? true : false} /></span></td>
        <td className="w-16 ">
            <div className="flex gap-2 items-center px-2">

                <div className="relative group w-full ">
                    <button className="bg-light border-2 border-muted text-primary rounded-full p-2 group-hover:bg-muted">
                        <BsThreeDots size={20} />
                    </button>
                    <div className="absolute left-0 top-15 group-hover:block hidden z-50  w-[250px] py-2">
                        <div className="group-hover:flex flex-col items-start bg-white border-2 border-muted text-sm p-2 shadow-lg">
                            <Link to={`${mineral.id}/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                <span >تعديل معلومات المعدن</span>
                            </Link>
                            <Link to={`${mineral.id}/description/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                <span >تعديل الوصف</span>
                            </Link>
                            <Link to={`${mineral.id}/history/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                <span >تعديل تاريخ </span>
                            </Link>
                            <Link to={`${mineral.id}/source/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                <span >تعديل المصدر</span>
                            </Link>
                            <Link to={`${mineral.id}/compounds/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                <span >تعديل المركبات</span>
                            </Link>
                            {mineral?.value_chain ? 
                                <Link to={`${mineral.id}/value-chain/edit`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                    <span >تعديل سلسلة القيمة</span>
                                </Link>
                                :
                                <Link to={`${mineral.id}/value-chain/add`} className="hover:bg-light border-transparent border-2 hover:border-muted  flex w-full py-2 px-4">
                                    <span >إضافة سلسلة القيمة</span>
                                </Link>
                                
                            }
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </tr>;
}

const GetCheckedIcon = ({ checked }) => {
    return (
        <>
            <div className="w-fit m-auto">
                {checked ?
                    <FaCircleCheck size={25} className="text-success" />
                    :
                    <FaCircleCheck size={25} className="opacity-10" />
                }
            </div>
        </>
    )
}