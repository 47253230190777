import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../contexts/authContext/authActions';
import { getRefValue, scrollToTop } from '../../helper';
import axiosClient from '../../config/axios-client';

export default function LoginPage() {

  const { authState, authDispatch } = useAuth();

  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});

  const emailRef = useRef(null);
  const passwordRef = useRef(null);



  // Functions 
  const handleOnClickLoginButton = () => {

    setIsSending(true);
    setErrors({});

    const payload = {
      email: getRefValue(emailRef),
      password: getRefValue(passwordRef),
    }

    axiosClient
      .post('/session/login', payload)
      .then(({ data }) => {
        authDispatch({
          type: AUTH_ACTIONS.LOGIN,
          payload: {
            user: data.user,
            token: data.token,
          },
        });
      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { data } = error.response;
          if (data && data.errors) {
            setErrors(data.errors);
          } else {
            console.error('Unexpected error format:', data);
            setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
          setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
        } else {
          // Something else happened while setting up the request
          console.error('Error setting up request:', error.message);
          setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  }

  useEffect(() => {

    // If token exists, redirect to the dashboard
    if (authState.token) {
      navigate('/', { replace: true });
    }
  }, [authState.token, navigate]);

  return (
    <div className='bg-light h-screen w-full flex'>
      {/* <Navbar /> */}
      <div className='w-[500px] m-auto'>
        <div className='bg-white border-2 border-muted p-8 mb-4'>
          <div>
            <h2 className='text-2xl font-extrabold mb-2'>
              تسجيل الدخول
            </h2>
            <p>المنصة العربية لمعادن المستقبل</p>
          </div>
          <div>
            {errors.general && <p className="text-danger">{errors.general[0]}</p>}
          </div>
          <form className='mt-8 flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <label className='font-semibold' htmlFor="ID_EMAIL">البريد الإلكتروني للمستخدم <span className='text-danger'>*</span> </label>
              <input ref={emailRef} name="email" id="ID_EMAIL" type="email" className='bg-light py-2.5 px-6 outline-none border-2' />
            </div>
            <div className='flex flex-col gap-2 w-full'>
              <label className='font-semibold' htmlFor="ID_PASSWORD">كلمة المرور <span className='text-danger'>*</span> </label>
              <input ref={passwordRef} name="password" id="ID_PASSWORD" type="password" className='bg-light py-2.5 px-6 outline-none border-2' />
            </div>
            <button onClick={handleOnClickLoginButton} type='button' className='bg-secondary py-3 px-6 outline-none font-bold text-white hover:bg-primary mt-2' >
              تسجيل الدخول
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}