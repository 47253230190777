import { Outlet } from "react-router-dom";
import DashboardSideBar from "../components/ui/DashboardSideBar";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useAuth } from "../contexts/authContext/AuthContext";
import { useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";

export default function DashboardLayout() {
    const navigate = useNavigate();
    const { authState } = useAuth();

    useLayoutEffect(() => {
        if (authState.currentUser?.role === "ADMIN") {
            // navigate('/minerals', { replace: true });
        }
    }, [authState.currentUser?.role]);
    return (<>
    <ToastContainer/>
        <div className="flex  min-h-screen w-full bg-light">
            <div className="flex">
                <DashboardSideBar />
            </div>
            <div className="p-4 w-full">
                <Outlet />
            </div>
        </div>
    </>)
}

