import { useEffect, useState } from "react";
import axiosClient from "../../config/axios-client";
import { Link } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { MdModeEdit } from "react-icons/md";

import { min } from "moment/moment";
import { getCategoryById } from "../../helper";
import { GrNext } from "react-icons/gr";
import { IoMdAdd, IoMdAddCircle } from "react-icons/io";
import MineralsRegionTableLoading from "../../components/ui/loadings/MineralsRegionTableLoading";

export default function MineralsRegionPage() {
    //const location = useLocation();
    const [minerals, setMinerals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const getMinerals = async () => {
        setIsLoading(true);

        axiosClient
            .get(`/dashboard/minerals/region`)
            .then(({ data }) => {
                setMinerals(data.minerals);
            })
            .catch((err) => {

            }).finally(() => {
                setIsLoading(false);
            })
            ;
    };

    useEffect(() => {
        getMinerals();
    }, []);

    return (<>
        <div className="">
            <div className="flex justify-between mb-5">
                <h2 className='text-primary font-bold text-lg'>
                    المعادن في المنطقة العربية
                </h2>
            </div>
            {/* table */}
            <div className="">
                <table className="w-full text-right">
                    <thead className="text-white text-center bg-primary ">
                        <th className="p-3 px-4">المعدن</th>
                        <th className="p-3 px-4">النوع</th>
                        <th className="p-3 px-4">الدول</th>
                        <th className="p-3 px-4">Actions</th>
                    </thead>
                    <tbody>
                        {!isLoading
                            ?
                            <>
                                {minerals && minerals.map((mineral) => {
                                    return (<>
                                        <MineralsRegionTableItem mineral={mineral} />
                                    </>)
                                })}
                            </>
                            :
                            <MineralsRegionTableLoading />
                        }
                    </tbody>
                </table>
            </div>
        </div>

    </>)
}



const MineralsRegionTableItem = ({ mineral }) => {

    const [isOpenMenu, setIsOpenMenu] = useState();

    return (<>
        <tr className="text-center odd:bg-white border-2 border-top-none bg-light">
            <td className="text-start">
                <div className="flex gap-4 p-2">
                    <img alt={mineral?.name_ar} src={`${process.env.REACT_APP_API_BASE_URL}/storage/minerals/${mineral?.image}`} className="h-12 w-12" />
                    <div className="">
                        <div className="font-bold text-primary">{mineral?.name_ar}</div>
                        <div className="line-clamp-1 text-primary">{mineral?.name_en}</div>
                    </div>
                </div>
            </td>
            <td className="py-2 px-4 text-primary"><span>{getCategoryById(mineral.category_id)}</span></td>
            <td className="py-2 px-4 group-hover:bg-muted/50">
                <div className="flex flex-wrap gap-2 items-start justify-start">
                    {mineral.countries.map((country) => {
                        return (
                            <div
                                key={`${mineral.id}-${country.id}`}
                                className="w-10 overflow-hidden border-2 border-dark"
                            >
                                <img
                                    title={country.name_ar}
                                    className="object-cover"
                                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${country.image}`}
                                    alt={`${country.name_ar}`}
                                />
                            </div>
                        );
                    })}
                </div>
            </td>
            <td className="">
                <Link to={`${mineral?.id}`} ></Link>
                <div className="flex gap-2">
                    <Link to={`${mineral?.id}/add`} title="إضافة دولة" className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary" >
                        <IoMdAdd />
                    </Link>
                    <Link to={`${mineral?.id}/edit`} className="bg-light p-2 border-2 border-muted hover:bg-muted text-primary" >
                        <MdModeEdit />
                    </Link>
                </div>
            </td>
        </tr>
    </>)
}

const GetCheckedIcon = ({ checked }) => {
    return (
        <>
            <div className="w-fit m-auto">
                {checked ?
                    <FaCircleCheck size={25} className="text-success" />
                    :
                    <FaCircleCheck size={25} className="opacity-10" />
                }
            </div>
        </>
    )
}