import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist/umd/react-router-dom.development";
import axiosClient from "../../config/axios-client";
import { getRefFile, getRefValue, scrollToTop, showToastError, showToastSuccess } from "../../helper";
import Select from "react-tailwindcss-select";
import { Editor } from "@tinymce/tinymce-react";
import editorConfig from "../../config/editorConfig";
import MineralRegionFormLoading from "../../components/ui/loadings/MineralRegionFormLoading";
import { Link } from "react-router-dom";

export default function EditMineralsRegionPage() {

  const { mineralId } = useParams();
  const navigate = useNavigate();

  const [mineralCountries, setMineralCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mineral, setMineral] = useState(null);
  const [selectedCountryDetail, setSelectedCountryDetail] = useState();

  const [isLoading, setIsLoading] = useState();
  const [errors, setErrors] = useState();
  const [isSending, setIsSending] = useState();

  const bodyArRef = useRef("");
  const bodyEnRef = useRef("");

  const sourceArRef = useRef("");
  const sourceEnRef = useRef("");


  const getMineral = () => {
    setIsLoading(true);
    setErrors({});
    axiosClient
      .get(`/dashboard/minerals/${mineralId}`)
      .then(({ data }) => {
        setMineral(data.mineral);

      })
      .catch((error) => {
        console.log(error);
        resetContents();

        // setErrors(err)
      }).finally(() => {
        setIsLoading(false);
      })
  }
  const getCountryMineral = (countryId) => {

    resetContents();
    setIsLoading(true);
    setErrors({});
    axiosClient
      .get(`/dashboard/minerals/${mineralId}/countries/${countryId}`)
      .then(({ data }) => {
        if (data) {
          setSelectedCountryDetail(data.details);
        }
      })
      .catch((error) => {
        console.log(error);
        resetContents();

        // setErrors(err)
      }).finally(() => {
        setIsLoading(false);
      })

  }
  const resetContents = () => {
    if (bodyArRef.current && bodyEnRef.current && sourceArRef.current && sourceEnRef.current) {
      bodyArRef.current.setContent("");
      bodyEnRef.current.setContent("");

      sourceArRef.current.setContent("");
      sourceEnRef.current.setContent("");
    }
  }
  const getCountries = () => {
    setIsLoading(true);
    setErrors({});
    axiosClient
      .get(`/dashboard/minerals/${mineralId}/countries/`)
      .then(({ data }) => {
        setMineralCountries(data.countries);
      })
      .catch((error) => {
        console.log(error);
        resetContents();

        // setErrors(err)
      }).finally(() => {
        setIsLoading(false);
      })
  }

  const handleUpdateMineralOnSubmit = () => {
    setErrors({});
    const payload = {
      description_ar: bodyArRef.current.getContent(),
      description_en: bodyEnRef.current.getContent(),
      source_ar: sourceArRef.current.getContent(),
      source_en: sourceEnRef.current.getContent(),
    }

    axiosClient
      .post(`/dashboard/minerals/${mineralId}/countries/${selectedCountry.value}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
          if(data.county_mineral_detail){
              navigate('/minerals-region', {
                replace: 'true'
              });      
              showToastSuccess('تم تعديل بنجاح');
          }  
          

      })
      .catch((error) => {
        scrollToTop();
        if (error.response) {
          const { data } = error.response;
          if (data && data.error) {
            setErrors(data.error);
            showToastError(data.error)
          } else {
            console.error('Unexpected error format:', data);
            setErrors({ general: ['حدث خطأ غير متوقع. حاول مرة أخرى لاحقًا.'] });
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
          setErrors({ general: ['لم يتم تلقي استجابة من الخادم. حاول مرة أخرى لاحقًا.'] });
        } else {
          // Something else happened while setting up the request
          console.error('Error setting up request:', error.message);
          setErrors({ general: ['حدث خطأ أثناء إعداد الطلب. حاول مرة أخرى لاحقًا.'] });
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  }


  const handleCountrySelectChange = (option) => {
    setSelectedCountry(option);
    getCountryMineral(option.value);
  };





  useEffect(() => {
    getMineral();
    getCountries()
    return () => { };
  }, [mineralId]);


  return (<>
    <div className='flex gap-0.5 font-bold mb-4'>
      <Link className='hover:text-secondary' to={'/minerals-region'} >
        المعادن في المنطقة
      </Link>
      <div>/</div>
      <div className="">{mineral?.name_ar}</div>
      <div>/</div>
      <h2 className='text-info'>
        تعديل
      </h2>
    </div>
    <div className="bg-white p-4 border-2">

      <div className="flex flex-col sm:flex-row w-full gap-4 mb-4">
        <div className="flex flex-col gap-2 w-full">
          <label className="font-bold">المعدن</label>
          {mineral ?
            <div className="outline-none border-2 border-muted py-2 px-4 bg-light ">
              <span>{mineral?.name_ar}</span>
            </div>

            :
            <div className="flex-1 animate-pulse">
              <div className="flex items-center gap-2 bg-white p-2 px-4 border">
                <div className="rounded-full h-[32px] w-[32px] bg-muted" />
                <span className="bg-muted p-2 grow"></span>
              </div>
            </div>
          }

        </div>


        <div className="flex flex-col gap-2 w-full ">
          <label className="font-bold">دولة</label>
          <Select
            value={selectedCountry}
            onChange={handleCountrySelectChange}
            options={mineralCountries.map((item) => {
              return {
                label: item.name_ar,
                image: item.image,
                value: item.id // Assuming you want to keep the ID for some purpose
              };
            })}
            // loading={isCountriesLoading}
            // isDisabled={isCountriesLoading}
            isSearchable
            placeholder="الدولة"
            isClearable
            classNames={{
              menuButton: ({ isDisabled }) =>
                `outline-none border-2 border-muted px-4 bg-light flex rounded-none`, // Apply rounded-none here
              searchContainer: "relative w-full",
              searchBox: "w-full outline-none py-2 px-2 rounded-none border-2 border-muted", // Added rounded-none here as well
              searchIcon: "absolute left-3 w-6 h-6 top-2.5",
              menu: `absolute z-50 -mt-1 w-full bg-white shadow-lg border-2 border-muted rounded-none p-2`,
            }}

            formatOptionLabel={(data) => (
              <li
                dir="rtl"
                className={`block z-50 text-primary transition duration-200 p-2 cursor-pointer select-none line-clamp-1  mt-1 ${data.isSelected
                  ? `bg-muted font-bold`
                  : ` font-semibold`
                  }`}
              >
                <div className="flex items-center gap-4">
                  <img
                    className="w-12 border-2"
                    src={`${process.env.REACT_APP_API_BASE_URL}/storage/flags/${data.image}`}
                    alt={data.label}
                  />
                  <div>{data.label}</div>
                </div>
              </li>
            )}

          />

        </div>
      </div>

      {/* details  */}
      {isLoading ?
        <MineralRegionFormLoading />
        :
        selectedCountry && <>
          <div className="flex flex-col gap-2 mb-4">
            <label className="font-bold">وصف بالعربية *</label>
            <div>
              <Editor
                apiKey={process.env.REACT_APP_TINY_KEY}
                init={editorConfig}
                onInit={(evt, editor) => (bodyArRef.current = editor)}
                initialValue={selectedCountryDetail?.description_ar}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-4">
            <label className="font-bold">وصف بالإنجليزية</label>
            <div>
              <Editor
                apiKey={process.env.REACT_APP_TINY_KEY}
                init={editorConfig}
                onInit={(evt, editor) => (bodyEnRef.current = editor)}
                initialValue={selectedCountryDetail?.description_en}

              />
            </div>
          </div>

          <div dir="rtl" className="flex flex-col gap-2 mb-4">
            <label className="font-bold">مصدر بالعربية</label>
            <Editor
              apiKey={process.env.REACT_APP_TINY_KEY}
              init={editorConfig}
              onInit={(evt, editor) => (sourceArRef.current = editor)}
              initialValue={selectedCountryDetail?.source_ar}

            />
          </div>
          <div dir="rtl" className="flex flex-col gap-2 mb-4">
            <label className="font-bold">مصدر بالإنجليزية</label>
            <Editor
              apiKey={process.env.REACT_APP_TINY_KEY}
              init={editorConfig}
              onInit={(evt, editor) => (sourceEnRef.current = editor)}
              initialValue={selectedCountryDetail?.source_en}
            />
          </div>
          {/* submit  */}
          <div className="my-5">
            <button
              onClick={handleUpdateMineralOnSubmit}
              className="py-3 px-6 bg-secondary hover:bg-primary w-full text-white font-bold duration-150 ease-in-out transform"
            >
              تعديل
            </button>
          </div>


        </>
      }

    </div>
  </>)
}
