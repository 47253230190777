import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../contexts/authContext/authActions';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../config/axios-client';

export default function DashboardSideBar() {

    const { authDispatch, authState } = useAuth();
    const navigate = useNavigate();

    const [isSending, setIsSending] = useState(false);

    const activeStyle = "border-secondary bg-light text-secondary"
    const inActiveStyle = "border-transparent hover:border-secondary hover:bg-light hover:text-secondary"

    const handleOnClickLogout = () => {
        setIsSending(true);
        axiosClient
            .post('/session/logout')
            .then(({ data }) => {
                console.log(data);
                authDispatch({
                    type: AUTH_ACTIONS.LOGOUT,
                });
                navigate('/session/login', {
                    replace: true,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsSending(false);
            });

    }

    return (
        <div className="min-w-[300px] min-h-screen bg-white hidden sm:block border-2">
            <div className="sticky top-0 right-0">
                <nav className="flex flex-col h-screen ">
                    <div className="flex flex-col font-bold h-full">
                        <NavLink
                            to='minerals'
                            className={({ isActive }) => `p-3 border-r-8  ${isActive ? activeStyle : inActiveStyle}`}
                        >المعادن</NavLink>

                        <NavLink to='minerals-region'
                            className={({ isActive }) => `p-3 border-r-8  ${isActive ? activeStyle : inActiveStyle}`}
                        >المعادن في المنطقة</NavLink>
                        <NavLink to='opportunities'
                            className={({ isActive }) => `p-3 border-r-8  ${isActive ? activeStyle : inActiveStyle}`}
                        >الفرص الاستثمارية</NavLink>
                        <NavLink to='form'
                            className={({ isActive }) => `p-3 border-r-8  ${isActive ? activeStyle : inActiveStyle}`}
                        > بيانات المعادن بالمنطقة العربية</NavLink>

                    </div>
                    <button disabled={isSending} onClick={handleOnClickLogout} className='bg-danger text-white py-3 hover:bg-danger/80 font-bold disabled:bg-muted disabled:text-secondary '>
                        {isSending ? 'جاري تسجيل الخروج...' : 'تسجيل الخروج'}
                    </button>
                </nav>
            </div>
        </div>
    );
}